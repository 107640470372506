import React, { useCallback, memo } from 'react';
import Dropdown from './dropdownComponent';
import { useOptionChain } from '../../contexts/OptionChainContext';
import { useWhyDidYouUpdate } from '../../hooks/useWhyDidYouUpdate';

const ContractsDropdown = memo(({ sortedContracts }) => {
  const { setOptionChains, activeOptionChainId, optionChains } =
    useOptionChain();

  const handleChange = useCallback(
    (event) => {
      const newSelectedContract = event.target.value;
      setOptionChains((prev) => ({
        ...prev,
        [activeOptionChainId]: {
          ...prev[activeOptionChainId],
          selectedContract: newSelectedContract,
        },
      }));
    },
    [setOptionChains, activeOptionChainId]
  );

  return (
    <Dropdown
      label="Select Contract"
      items={sortedContracts}
      valueKey="contract_term"
      labelKey="contract_term"
      value={optionChains[activeOptionChainId]?.selectedContract || ''}
      onChange={handleChange}
    />
  );
});

export default ContractsDropdown;
