import React, { useState, useEffect } from 'react';
import WorkingOrders from '../../components/orders/WorkingOrders';
import CompletedOrders from '../../components/orders/CompletedOrders';
import { Grid } from '@mui/material'; // Import MUI's Grid component

const Orders = () => {
  const [renderedTables, setRenderedTables] = useState({
    workingOrders: false,
    completedOrders: false,
  });

  // Mount workingOrders first
  useEffect(() => {
    setRenderedTables((prev) => ({ ...prev, workingOrders: true }));
  }, []);

  // Once workingOrders is mounted, mount pausedOrders
  useEffect(() => {
    if (renderedTables.workingOrders && !renderedTables.completedOrders) {
      setRenderedTables((prev) => ({ ...prev, completedOrders: true }));
    }
  }, [renderedTables.workingOrders]);

  return (
    <Grid
      container
      spacing={0.5}
      style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}
    >
      <Grid item xs={12} style={{ flex: 1 }}>
        {renderedTables.workingOrders && <WorkingOrders />}
      </Grid>

      <Grid item xs={12} style={{ flex: 1 }}>
        {renderedTables.completedOrders && <CompletedOrders />}
      </Grid>
    </Grid>
  );
};

export default Orders;
