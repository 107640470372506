import React, { createContext, useContext, useState } from 'react';
import { useDB } from '../db/indexedDB/DBContext';
import {
  WatchListComponentID,
  watchListProps,
  OptionChainComponentID,
  optionChainProps,
} from '../utils/compenentProps';
import { useTable } from './TableContext';

const TabsContext = createContext();

export const TabsProvider = ({ children }) => {
  const [tabs, setTabs] = useState([]);
  const { db, selectRows, upsertRows, deleteRows } = useDB(); // Using methods from DBContext
  const { removeTables } = useTable();

  const fetchAndSetTabs = async (tabsIds) => {
    if (db && tabsIds.length > 0) {
      const fetchedArray = await selectRows('tabs', tabsIds);
      const fetchedTabs = fetchedArray.reduce((acc, item) => {
        if (item && item.tabsId) {
          acc[item.tabsId] = item;
        }
        return acc;
      }, {});

      // Update the component's state
      setTabs((prev) => ({ ...prev, ...fetchedTabs }));
    }
  };

  const initialTabs = (tabsId, tabsList, editable) => ({
    tabsId: tabsId,
    tabsList: tabsList || [],
    activeTabIndex: 0,
    editable: editable,
  });

  const initialSingleTab = (tabsId, label, contents, max = null) => {
    let newTabId;

    if (max !== null) {
      // If max is provided, use it to construct the new tab ID
      newTabId = `${tabsId}_${max + 1}`;
    } else {
      // Access tabs list from state, default to an empty array if tabsList not found
      const tabsList = tabs[tabsId]?.tabsList || [];

      // Calculate the maximum ID suffix from the existing tabs
      const calculatedMax = tabsList.reduce((acc, item) => {
        // Assuming tabId is in the format 'tabsId_number'
        const parts = item.tabId.split('_');
        const lastPart = parts.pop();
        const number = parseInt(lastPart, 10);
        return isNaN(number) ? acc : Math.max(acc, number);
      }, 0); // Start with 0 for an empty list

      // Create the new tab ID by appending the calculated max + 1
      newTabId = `${tabsId}_${calculatedMax + 1}`;
    }

    // Assuming contents is an array of objects where each object has a componentId and possibly other properties

    const list = contents.map((c) => {
      switch (c.componentId) {
        case WatchListComponentID:
          return {
            componentId: c.componentId,
            props: { ...watchListProps(newTabId) },
          };

        case OptionChainComponentID:
          return {
            componentId: c.componentId,
            props: { ...optionChainProps(newTabId) },
          };

        default:
          return {
            componentId: c.componentId,
          };
      }
    });

    return {
      tabId: newTabId,
      label: label,
      contents: list,
    };
  };

  const updateTabs = (tabsIds, updates) => {
    const rows = tabsIds
      .map((id) => updates[id])
      .filter((update) => update !== undefined);
    upsertRows('tabs', rows);
  };

  const switchSingleTab = (tabsId, newActiveTabIndex) => {
    setTabs((prev) => {
      const currentTabsGroup = prev[tabsId];
      if (!currentTabsGroup) {
        console.error('No tabs group found with the given ID:', tabsId);
        return prev; // Return the current state unchanged if tabsId is not found
      }

      // Create a new object for the tabs group, updating only the activeTabIndex
      const updatedTabsGroup = {
        ...currentTabsGroup,
        activeTabIndex: newActiveTabIndex,
      };

      return {
        ...prev,
        [tabsId]: updatedTabsGroup,
      };
    });
  };

  const reorderTabs = (tabsId, startIndex, endIndex) => {
    setTabs((prev) => {
      const currentTabs = prev[tabsId];
      if (!currentTabs || startIndex === endIndex) {
        console.error('Invalid operation or tabs group not found:', tabsId);
        return prev; // Early return if conditions are not met
      }

      const tabsList = currentTabs.tabsList;
      const result = Array.from(tabsList); // Clone the array to avoid mutating the original
      const [removed] = result.splice(startIndex, 1); // Remove the tab at startIndex
      result.splice(endIndex, 0, removed); // Insert it at endIndex

      return {
        ...prev,
        [tabsId]: {
          ...currentTabs,
          tabsList: result, // Update the tabs list with the reordered tabs
        },
      };
    });
  };

  const removeSingleTab = (tabsId, removedIndex, canEdit) => {
    if (!canEdit) {
      console.log('Editing not allowed');
      return;
    }

    setTabs((prevTabs) => {
      const tabsGroup = prevTabs[tabsId];
      if (!tabsGroup) {
        console.error('No tab group found with the given ID:', tabsId);
        return prevTabs;
      }

      const tabsList = tabsGroup.tabsList;

      // Check if the provided index is valid
      if (removedIndex < 0 || removedIndex >= tabsList.length) {
        console.error('Invalid index for removal:', removedIndex);
        return prevTabs;
      }

      // Filter out the removed tab using the index
      const filteredTabs = [...tabsList].filter(
        (_, index) => index !== removedIndex
      );

      const removedTabs = tabsList.filter((_, index) => index === removedIndex); // Capture the removed tabs

      const removedTableIds = [];
      removedTabs.forEach((tab) => {
        const contents = tab?.contents || [];
        contents.forEach((content) => {
          if (content.componentId === WatchListComponentID) {
            removedTableIds.push(content.props.tableId);
          }
        });
      });

      // slight delay to avoid racing with VTable unmount in useDataSaver which will save deleted table state in database.
      if (Object.keys(removedTableIds).length > 0) {
        setTimeout(() => {
          removeTables(removedTableIds);
        }, 20); // Delay for 1 second (1000 ms)
      }

      // Determine the new activeTabIndex
      let newActiveTabIndex = tabsGroup.activeTabIndex;
      if (tabsGroup.activeTabIndex === removedIndex) {
        // If the removed tab was the active tab
        if (removedIndex >= filteredTabs.length) {
          // If it was the last tab, activate the previous one
          newActiveTabIndex = filteredTabs.length - 1;
        } else {
          // Otherwise, keep the next tab in the same position active
          newActiveTabIndex = removedIndex;
        }
      } else if (tabsGroup.activeTabIndex > removedIndex) {
        // Adjust activeTabIndex if it was after the removed one
        newActiveTabIndex--;
      }

      return {
        ...prevTabs,
        [tabsId]: {
          ...tabsGroup,
          tabsList: filteredTabs,
          activeTabIndex: newActiveTabIndex,
        },
      };
    });
  };

  const addSingleTab = (tabsId, label, contents) => {
    const newTab = initialSingleTab(tabsId, label, contents);

    // Update state in the context
    setTabs((prev) => ({
      ...prev,
      [tabsId]: {
        ...prev[tabsId],
        tabsList: [...(prev[tabsId]?.tabsList || []), newTab], // Safely handle undefined tabsList
        activeTabIndex: [...(prev[tabsId]?.tabsList || []), newTab].length - 1,
      },
    }));
  };

  const updateTabLabel = (tabsId, index, newLabel) => {
    setTabs((prevTabs) => {
      const updatedTabs = [...prevTabs[tabsId].tabsList];
      updatedTabs[index].label = newLabel;
      return {
        ...prevTabs,
        [tabsId]: {
          ...prevTabs[tabsId],
          tabsList: updatedTabs,
        },
      };
    });
  };

  return (
    <TabsContext.Provider
      value={{
        tabs,
        setTabs,
        fetchAndSetTabs,
        initialTabs,
        updateTabs,
        initialSingleTab,
        switchSingleTab,
        reorderTabs,
        removeSingleTab,
        addSingleTab,
        updateTabLabel,
      }}
    >
      {children}
    </TabsContext.Provider>
  );
};

export const useTabs = () => useContext(TabsContext);
