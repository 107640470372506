import CustomGroupHeader from '../testheader';

export const underlyingFields = [
  {
    items: [
      { span: 1, col: { key: 'last', label: 'LAST', editable: false } },
      {
        span: 1,
        col: { key: 'lastSize', label: 'LAST SIZE', editable: false },
      },
      { span: 1, col: { key: 'volume', label: 'VOLUME', editable: false } },
      { span: 1, col: { key: 'bid', label: 'BID', editable: false } },
      { span: 1, col: { key: 'bidSize', label: 'BID SIZE', editable: false } },
      { span: 1, col: { key: 'ask', label: 'ASK', editable: false } },
    ],
  },
  {
    items: [
      { span: 1, col: { key: 'askSize', label: 'ASK SIZE', editable: false } },
      { span: 1, col: { key: 'change', label: 'CHANGE', editable: false } },
      {
        span: 1,
        col: { key: 'changePercent', label: 'CHANGE, %', editable: false },
      },
      { span: 1, col: { key: 'open', label: 'OPEN', editable: false } },
      { span: 1, col: { key: 'high', label: 'HIGH', editable: false } },
      { span: 1, col: { key: 'low', label: 'LOW', editable: false } },
    ],
  },
  {
    items: [
      {
        span: 1,
        col: { key: 'prevClose', label: 'PREV. CLOSE', editable: false },
      },
      {
        span: 1,
        col: { key: 'expiryDate', label: 'EXPIRY DATE', editable: false },
      },
      {
        span: 1,
        col: {
          key: 'prevSettlementPrice',
          label: 'PREVIOUS SETTLEMENT PRICE',
          editable: false,
        },
      },
    ],
  },
];

export const optionColumns = [
  {
    headerName: 'Call',
    headerClass: 'call-header-group',
    children: [
      {
        headerName: 'Last',
        field: 'call_last',
        cellClass: 'call-cell',
        trackOldValue: true,
      },
      {
        headerName: 'IV',
        field: 'call_iv',
        cellClass: 'call-cell',
        trackOldValue: true,
      },
      {
        headerName: 'Ask',
        field: 'call_ask',
        cellClass: 'call-cell',
        trackOldValue: true,
      },
      {
        headerName: 'Bid',
        field: 'call_bid',
        cellClass: 'call-cell',
        trackOldValue: true,
      },
      {
        headerName: 'Delta',
        field: 'call_delta',
        cellClass: 'call-cell',
        trackOldValue: true,
      },
      {
        headerName: 'Gamma',
        field: 'call_gamma',
        cellClass: 'call-cell',
        trackOldValue: true,
      },
      {
        headerName: 'Vega',
        field: 'call_vega',
        cellClass: 'call-cell',
        trackOldValue: true,
      },
      {
        headerName: 'Theta',
        field: 'call_theta',
        cellClass: 'call-cell',
        trackOldValue: true,
      },
      {
        headerName: 'Rho',
        field: 'call_rho',
        cellClass: 'call-cell',
        trackOldValue: true,
      },
      // ... add any other call option columns here
    ],
  },
  {
    headerName: 'Strike',
    // Specify the custom header component for the group header.
    headerGroupComponent: CustomGroupHeader,
    headerClass: 'dropdown-header-group', // Apply custom class for styling
    children: [
      {
        headerName: 'Strike', // The column for the dropdown
        field: 'strike',
        cellClass: 'strike-cell',
        isRowId: true,
      },
    ],
  },
  {
    headerName: 'Put',
    headerClass: 'put-header-group',
    children: [
      {
        headerName: 'Last',
        field: 'put_last',
        cellClass: 'put-cell',
        trackOldValue: true,
      },
      {
        headerName: 'IV',
        field: 'put_iv',
        cellClass: 'put-cell',
        trackOldValue: true,
      },
      {
        headerName: 'Ask',
        field: 'put_ask',
        cellClass: 'put-cell',
        trackOldValue: true,
      },
      {
        headerName: 'Bid',
        field: 'put_bid',
        cellClass: 'put-cell',
        trackOldValue: true,
      },
      {
        headerName: 'Delta',
        field: 'put_delta',
        cellClass: 'put-cell',
        trackOldValue: true,
      },
      {
        headerName: 'Gamma',
        field: 'put_gamma',
        cellClass: 'put-cell',
        trackOldValue: true,
      },
      {
        headerName: 'Vega',
        field: 'put_vega',
        cellClass: 'put-cell',
        trackOldValue: true,
      },
      {
        headerName: 'Theta',
        field: 'put_theta',
        cellClass: 'put-cell',
        trackOldValue: true,
      },
      {
        headerName: 'Rho',
        field: 'put_rho',
        cellClass: 'put-cell',
        trackOldValue: true,
      },
      // ... add any other put option columns here
    ],
  },
];
