import { formatTimestamp } from '../../../utils/dateTime';
import { adjustToTickSize } from '../../../utils/numbers';

// Utility function to safely get values, allowing 0 as a valid value
function getValueOrDefault(value, defaultValue = '') {
  return value === undefined || value === null ? defaultValue : value;
}

// Use this function to map data
export function mapMarketDataUpdate(data) {
  const transformedData = {
    symbol: getValueOrDefault(data.contract_id),
    last: getValueOrDefault(data.last?.p),
    lastSize: getValueOrDefault(data.last?.v),
    volume: getValueOrDefault(data.vol),
    bid: getValueOrDefault(data.bid?.p),
    bidSize: getValueOrDefault(data.bid?.v),
    ask: getValueOrDefault(data.offer?.p),
    askSize: getValueOrDefault(data.offer?.v),
    change: '',
    changePercent: '',
    open: getValueOrDefault(data.o),
    high: getValueOrDefault(data.h),
    low: getValueOrDefault(data.l),
    prevClose: '',
    expiryDate: '',
    description: '',
    lastUpdate: formatTimestamp(getValueOrDefault(data.timestamp)),
    symbolStatus: getValueOrDefault(data.status),
    prevSettlementPrice: getValueOrDefault(data.pre_sett),
  };

  return transformedData;
}

export function mapMarketDataDOMUpdate(data, tickSize) {
  return [
    // Process offer_dom in reverse order and push first
    ...data.offer_dom
      .slice()
      .reverse()
      .map((item) => ({
        p: adjustToTickSize(item.p, tickSize),
        ov: item.v || '',
      })),

    // Process bid_dom in original order and push next
    ...data.bid_dom.map((item) => ({
      p: adjustToTickSize(item.p, tickSize),
      bv: item.v || '',
    })),
  ];
}
