export const http = 'https';
export const ws = 'wss';

// Initialize default configurations based on the environment
const defaultConfig = {
  baseURL: `${http}://testwebapi.tradex.com.sg:11100`,
  webSocketURL: `${ws}://testwebapi.tradex.com.sg:11100/ws`,
};

class Config {
  constructor() {
    this.baseURL = defaultConfig.baseURL;
    this.webSocketURL = defaultConfig.webSocketURL;
    this.listeners = []; // Stores functions to call when config updates
  }

  // Update configuration and notify listeners
  update(configUpdates) {
    let changed = false;

    if (configUpdates.baseURL && configUpdates.baseURL !== this.baseURL) {
      this.baseURL = configUpdates.baseURL;
      changed = true;
    }
    if (
      configUpdates.webSocketURL &&
      configUpdates.webSocketURL !== this.webSocketURL
    ) {
      this.webSocketURL = configUpdates.webSocketURL;
      changed = true;
    }

    // If there's a change, notify all listeners
    if (changed) {
      this.notifyListeners();
    }
  }

  // Subscribe method that returns a cleanup function
  subscribe(listener) {
    this.listeners.push(listener);
    // Immediately invoke the listener with current configuration
    listener(this.getConfig());

    // Return a cleanup function that removes the listener
    return () => {
      this.listeners = this.listeners.filter((l) => l !== listener);
    };
  }

  // Notify all listeners of the current config
  notifyListeners() {
    this.listeners.forEach((listener) => listener(this.getConfig()));
  }

  // Get the current configuration
  getConfig() {
    return {
      baseURL: this.baseURL,
      webSocketURL: this.webSocketURL,
    };
  }
}

const globalConfig = new Config();

export default globalConfig;
