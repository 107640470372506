import React, { createContext, useContext, useEffect, useState } from 'react';
import { SharedWorkerClient } from '../services/broadcast/broadcastWorkerClient';

const BroadcastContext = createContext();

export const BroadcastProvider = ({ children }) => {
  const [broadcastClient, setBroadcastClient] = useState(null);

  useEffect(() => {
    // Retrieve the singleton instance of SharedWorkerClient
    const client = SharedWorkerClient.getInstance('/broadcastWorker.js');
    setBroadcastClient(client);

    console.log('Worker initialized in window:', window.name);

    return () => {
      // Cleanup the worker when the component unmounts or window closes
      if (client) {
        console.log('Cleaning up worker in window:', window.name);
        client.close();
      }
    };
  }, []); // Run only once

  // Method to update the worker or send an update message
  const updateBroadcastClient = (message) => {
    if (broadcastClient) {
      broadcastClient.sendMessage(message); // Example of sending an update message
    }
  };

  return (
    <BroadcastContext.Provider
      value={{ broadcastClient, updateBroadcastClient }}
    >
      {children}
    </BroadcastContext.Provider>
  );
};

// Custom hook to access the Broadcast Worker in the window's isolated app tree
export const useBroadcast = () => useContext(BroadcastContext);
