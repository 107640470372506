import React from 'react';
import { Grid } from '@mui/material';
import MemoizedCell from './MemoizedCell';

const CustomGrid = ({ config, data }) => {
  return (
    <Grid container spacing={1}>
      {config.map((row, rowIndex) => {
        return row.items.map((item, itemIndex) => {
          const key = item.col.key;
          const value = data[key];

          return (
            <Grid
              item
              xs={12}
              sm={item.span}
              key={`row-${rowIndex}-item-${itemIndex}`}
            >
              <MemoizedCell
                label={item.col.label}
                value={value}
                isEditable={item.col.editable}
                onChange={(e) =>
                  console.log(`Updating ${item.col.key}: ${e.target.value}`)
                }
              />
            </Grid>
          );
        });
      })}
    </Grid>
  );
};

export default CustomGrid;
