// useTranslation.js
import { useContext } from 'react';
import { TranslationContext } from '../contexts/translationContext';

export const useTranslation = () => {
  const { t, i18n } = useContext(TranslationContext);

  // Enhance the 't' function to process keys in lowercase and handle missing translations
  const translate = (key, options) => {
    const lowerKey = key.toLowerCase(); // Convert key to lowercase
    const translatedValue = t(lowerKey, options); // Attempt to get the translation

    // Check if the translation for the lowerKey is the same as lowerKey itself,
    // which typically means no translation was found.
    if (translatedValue === lowerKey) {
      // Return the original key if no translation is found
      return key;
    }

    return translatedValue; // Return the translation if found
  };
  return { t: translate, i18n };
};
