export const validateNumber = (
  value,
  { helperText = 'Please enter a valid number', condition } = {}
) => {
  if (isNaN(value)) {
    return {
      isValid: false,
      helperText: helperText || 'Please enter a valid number',
    };
  }

  if (condition && !condition(Number(value))) {
    return {
      isValid: false,
      helperText: helperText || 'Condition not met',
    };
  }

  return {
    isValid: true,
    helperText: '',
  };
};

export const greaterThanZero = (value) =>
  validateNumber(value, {
    helperText: 'Please enter a number greater than 0',
    condition: (num) => num > 0,
  });

export const adjustToTickSize = (value, tickSize) => {
  const decimalPlaces = (tickSize.toString().split('.')[1] || []).length; // Get the number of decimal places from tickSize
  const factor = Math.pow(10, decimalPlaces); // Use this factor to scale and round
  return (Math.round(value * factor) / factor).toFixed(decimalPlaces); // Format as string with fixed decimals
};
