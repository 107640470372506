import React, { useState } from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import { Select, MenuItem } from '@mui/material';

function EnvironmentSelect() {
  const { t } = useTranslation(); // `t` function to use translations
  const [environment, setEnvironment] = useState('production');

  const handleChange = (environment) => {
    setEnvironment(environment);
  };

  return (
    <Select
      value={environment}
      onChange={(e) => handleChange(e.target.value)}
      displayEmpty
      size="small"
      style={{ minWidth: 90 }} // Set a minimum width for the dropdown
    >
      <MenuItem value="production">{t('Production')}</MenuItem>
      <MenuItem value="demo">{t('Demo')}</MenuItem>
    </Select>
  );
}

export default EnvironmentSelect;
