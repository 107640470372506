import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react';
import initializeWebSocketService from '../services/websocket/initWebSocketService';
import globalConfig from '../config/config';
import { useError } from '../errorHandler/ErrorContext';
import { useAuth } from './AuthContext';
import { useBroadcast } from './BroadcastChannelContext';
import { mainWindow } from '..';

export const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {
  const [isToConnect, setIsToConnect] = useState(false);
  const [webSocketInstance, setWebSocketInstance] = useState(null);
  const [isWebSocketConnected, setIsWebSocketConnected] = useState(false);
  const { handleError } = useError();
  const { isAuthenticated, token, logout } = useAuth();
  const [isLoading, setIsLoading] = useState(false); // Corrected this line

  const { broadcastClient } = useBroadcast(); // Use the broadcast client from BroadcastContext

  useEffect(() => {
    console.log('websocket inti', window.name);
    return () => {
      // Cleanup the worker when the window is closed or app unmounts

      console.log('websocket unmount:', window.name);
    };
  }, []);

  // Method to disconnect WebSocket
  const disconnectWebSocket = useCallback(() => {
    if (webSocketInstance) {
      if (webSocketInstance.disconnect) {
        webSocketInstance.disconnect();
      }
      // If the sharedWorker is part of the webSocketInstance, clear its event listener
      if (
        webSocketInstance.sharedWorker &&
        webSocketInstance.sharedWorker.port
      ) {
        webSocketInstance.sharedWorker.port.onmessage = null;
      }
      setWebSocketInstance(null);
      setIsToConnect(false);
      setIsWebSocketConnected(false);
      console.log('WebSocket disconnected on logout');
    }
  }, [webSocketInstance]);

  useEffect(() => {
    // Ensure broadcastClient is available
    if (broadcastClient && window.name === mainWindow) {
      const windowId = window.name;
      broadcastClient.sendMessage({
        type: 'WebSocketStateUpdate',
        data: {
          // Wrap state inside a 'data' object
          isWebSocketConnected,
          isToConnect,
        },
        windowId,
      });
    }
  }, [isWebSocketConnected, isToConnect]);

  // Set up the broadcast listener to handle WebSocket state updates
  useEffect(() => {
    if (broadcastClient) {
      // Define the callback function
      const handleWebSocketStateUpdate = (type, data) => {
        console.log(type, data);
        if (['WebSocketStateUpdate', 'CurrentState'].includes(type)) {
          const { isWebSocketConnected, isToConnect } = data;

          // Update the state based on the broadcast message
          if (typeof isWebSocketConnected !== 'undefined') {
            setIsWebSocketConnected(isWebSocketConnected);
          }
          if (typeof isToConnect !== 'undefined') {
            setIsToConnect(isToConnect);
          }
        }
      };

      // Set the callback on the broadcast client
      console.log(window.name);
      broadcastClient.setOnMessageCallback(handleWebSocketStateUpdate);
      broadcastClient.retrieveCurrentState();

      // Cleanup function when the component unmounts
      return () => {
        console.log('clean msg');
        broadcastClient.setOnMessageCallback(null); // Remove the listener when done
      };
    }
  }, [broadcastClient]); // Run this effect whenever broadcastClient changes

  // Inside WebSocketProvider
  useEffect(() => {
    if (!isAuthenticated) {
      disconnectWebSocket();
    }
  }, [isAuthenticated, disconnectWebSocket]);

  // Initialize WebSocket instance when the user is authenticated
  useEffect(() => {
    if (isToConnect) {
      const instance = initializeWebSocketService(token);

      instance.sharedWorker.port.onmessage = (event) => {
        const { type } = event.data;
        // console.log(result);
        // Dispatch to listeners based on message type
        const listeners = instance.listeners[type] || [];
        listeners.forEach((listener) => listener(event.data));
        if (type === 'AUTH') {
          setIsWebSocketConnected(true); // Set when WebSocket is open
        }
        if (type === 'CLOSE') {
          setIsWebSocketConnected(false); // Reset when WebSocket closes
          setIsToConnect(false);
          setIsLoading(false);
          const errMsg = 'WEBSOCKET IS CLOSED.';
          if (event.data.result.code !== 1000) {
            handleError(event.data.result.message, errMsg);
          }
        }
        if (type === 'ERROR') {
          console.log(event.data);
        }
        if (event.data.response_code === 3000) {
          if (event.data.error_code === 'SOCKET0005') {
            setIsToConnect(false);
            logout();
          }

          handleError(
            event.data.error_code,
            event.data.error_message,
            event.data.action_message
          );
        }
      };
      if (isWebSocketConnected) {
        setWebSocketInstance(instance);
      }

      return () => {
        disconnectWebSocket(); // Clean up WebSocket when user logs out or component unmounts
      };
    }
  }, [isToConnect, isWebSocketConnected]);

  useEffect(() => {
    if (webSocketInstance) {
      // Subscribe to configuration changes
      const unsubscribe = globalConfig.subscribe((config) => {
        console.log(window.name);
        console.log(
          'Configuration changed: Updating WebSocket URL if necessary.'
        );
        if (webSocketInstance.getWebSocketURL() !== config.webSocketURL) {
          console.log(webSocketInstance.getWebSocketURL());
          console.log(config.webSocketURL);
          webSocketInstance.updateWebSocketURL(config.webSocketURL);
        }
      });

      // Return the cleanup function to be called when the component unmounts or webSocketInstance changes
      return () => {
        unsubscribe(); // Unsubscribe when the component unmounts or the dependency changes
      };
    }
  }, [webSocketInstance]); // Ensure effect is re-run if webSocketInstance changes

  return (
    <WebSocketContext.Provider
      value={{
        webSocketInstance,
        isWebSocketConnected,
        setIsToConnect,
        disconnectWebSocket,
        setIsLoading,
        isLoading,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => useContext(WebSocketContext);
