import { mainWindow } from '../..';

export class SharedWorkerClient {
  constructor(workerPath) {
    if (SharedWorkerClient.instance) {
      return SharedWorkerClient.instance; // Return existing instance if available
    }

    // Initialize the shared worker
    this.worker = new SharedWorker(workerPath);
    this.worker.port.start(); // Start the port to communicate

    // Register the connection with a unique identifier (e.g., window name)
    this.worker.port.postMessage({
      type: 'RegisterWindow',
      windowId: window.name || 'defaultWindowId', // Use window.name or another identifier
    });

    SharedWorkerClient.instance = this; // Save this instance as the singleton
  }

  // Method to send messages to the SharedWorker
  sendMessage(message) {
    this.worker.port.postMessage(message);
  }

  // Method to retrieve the current state from the SharedWorker
  retrieveCurrentState() {
    const windowId = window.name === mainWindow ? mainWindow : '';
    this.worker.port.postMessage({
      type: 'RequestCurrentState',
      windowId: windowId,
      targetWindowId: window.name,
    });
  }

  // Method to set the message handler (onMessageCallback)
  setOnMessageCallback(onMessageCallback) {
    this.worker.port.onmessage = (event) => {
      const { type, data, targetWindowId } = event.data;
      const currentWindowId = window.name || 'defaultWindowId';

      // Only process the message if it's meant for this window or if no targetWindowId is specified
      if (!targetWindowId || targetWindowId === currentWindowId) {
        if (onMessageCallback) {
          onMessageCallback(type, data);
        }
      }
    };
  }

  // Method to close the connection to the SharedWorker
  close() {
    this.worker.port.postMessage({
      type: 'DisconnectWindow',
      windowId: window.name || 'defaultWindowId', // Provide the window's identifier
    });
    this.worker.port.close();
    SharedWorkerClient.instance = null; // Reset singleton instance on close
  }

  // Static method to get or create the singleton instance
  static getInstance(workerPath) {
    if (!SharedWorkerClient.instance) {
      SharedWorkerClient.instance = new SharedWorkerClient(workerPath);
    }
    return SharedWorkerClient.instance;
  }
}
