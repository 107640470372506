import React, { useEffect, useState } from 'react';
import VTable from '../components/tables/VTable';
import WindowComponent from '../components/windows/WindowComponent';
import { useTable } from '../contexts/TableContext';
import { getFills } from '../services/trades/getFills';
import { useAuth } from '../contexts/AuthContext';
import { useMetadata } from '../contexts/MetadataContext';

const columns = [
  {
    headerName: 'FILL ID',
    field: 'fillId',
    sortable: true,
    filter: true,
    isRowId: true,
  },
  {
    headerName: 'C-STRATEGY',
    field: 'cStrategy',
    sortable: true,
    filter: true,
  },
  { headerName: 'ORDER ID', field: 'orderId', sortable: true, filter: true },
  { headerName: 'STATUS', field: 'status', sortable: true, filter: true },
  { headerName: 'ACCOUNT', field: 'account', sortable: true, filter: true },
  { headerName: 'USER ID', field: 'userId', sortable: true, filter: true },
  { headerName: 'EXCHANGE', field: 'exchange', sortable: true, filter: true },
  { headerName: 'COMMODITY', field: 'commodity', sortable: true, filter: true },
  {
    headerName: 'DATE',
    field: 'date',
    sortable: true,
    filter: 'agDateColumnFilter',
  },
  { headerName: 'SIDE', field: 'side', sortable: true, filter: true },
  { headerName: 'LOTS', field: 'lots', sortable: true, filter: true },
  { headerName: 'PRICE', field: 'price', sortable: true, filter: true },
  {
    headerName: 'FILLED TIME',
    field: 'filledTime',
    sortable: true,
    filter: 'agDateColumnFilter',
  },
  { headerName: 'O/C', field: 'oc', sortable: true, filter: true },
  {
    headerName: 'HEDGE FLAG',
    field: 'hedgeFlag',
    sortable: true,
    filter: true,
  },
  {
    headerName: 'COMMISSION',
    field: 'commission',
    sortable: true,
    filter: true,
  },
  { headerName: 'REMARKS', field: 'remarks', sortable: true, filter: true },
];

const Fills = () => {
  const { activeTableId, setActiveTableId } = useTable();
  const [fillsData, setFillsData] = useState([]);
  const { currentUser } = useAuth();
  const { enumList } = useMetadata();

  useEffect(() => {
    // Set the current table ID when the component mounts
    setActiveTableId('fillsTable');
  }, [setActiveTableId]);

  useEffect(() => {
    const fetchFillsData = async () => {
      try {
        const fetchedFillsByTrader = await getFills(currentUser);

        // Flatten the dictionary of fills into a single array and map to the expected format
        const allFills = Object.values(fetchedFillsByTrader)
          .flat()
          .map((fill) => ({
            fillId: fill.fill_id, // Assuming 'fill_id' is the correct key
            cStrategy: fill.c_strategy, // Adjust these keys based on your actual data structure
            orderId: fill.broker_order_id, // Based on the screenshot provided
            status: enumList.order_status?.[fill.status] || '',
            account: fill.trader_account,
            exchange: fill.exchange_name,
            commodity: fill.product_name,
            date: fill.contract_date, // Make sure the format of the date matches what VTable expects
            side: enumList.order_side?.[fill.side] || '',
            lots: fill.lots,
            price: fill.price,
            filledTime: fill.filled_time, // Ensure the time format is correct for display
            oc: enumList.open_close?.[fill.open_close] || '',
            hedgeFlag: enumList.hedge_flag?.[fill.hedge_flag] || '',
            commission: fill.commission,
            remarks: fill.remarks || '', // Provide an empty string if remarks are undefined
            userId: fill.user_id,
            // Include any other necessary mappings
          }));

        // Update the state with the flattened and mapped fills
        setFillsData(allFills);
      } catch (error) {
        console.error('Error fetching fills:', error);
        // Handle the error appropriately
      }
    };
    if (activeTableId === 'fillsTable') {
      fetchFillsData();
    }
  }, [activeTableId]);

  const fillsConfig = {
    autoSizeMode: 'sizeColumnsToContentStrategy',
    cols: columns,
    autoSave: true,
    paginationPageSize: 200,
  };

  return (
    <WindowComponent title="My Fills">
      <VTable
        tableId={activeTableId}
        insertRows={fillsData}
        config={fillsConfig}
      />
    </WindowComponent>
  );
};

export default Fills;
