import React, { createContext, useContext, useState } from 'react';
import { useDB } from '../db/indexedDB/DBContext';

// Create the context
const OptionChainContext = createContext();

// Create the provider component
export const OptionChainProvider = ({ children }) => {
  const { db, selectRows, upsertRows } = useDB(); // Using methods from DBContext
  const [optionChains, setOptionChains] = useState({});
  const [activeOptionChainId, setActiveOptionChainId] = useState(null);

  const fetchAndSetOptionChains = async (optionChainIds) => {
    if (db && optionChainIds.length > 0) {
      const fetchedArray = await selectRows('optionChains', optionChainIds);
      const fetchedOptionChains = fetchedArray.reduce((acc, item) => {
        if (item && item.optionChainId) {
          acc[item.optionChainId] = item;
        }
        return acc;
      }, {});

      // Update the component's state
      setOptionChains((prev) => ({ ...prev, ...fetchedOptionChains }));
    }
  };

  const updateOptionChains = (optionChainIds, updates) => {
    const rows = optionChainIds
      .map((id) => updates[id])
      .filter((update) => update !== undefined);
    upsertRows('optionChains', rows);
  };

  const initialOptionChain = (
    optionChainId,
    selectedProduct,
    selectedContract
  ) => ({
    optionChainId: optionChainId,
    selectedProduct: selectedProduct || '',
    selectedContract: selectedContract || '',
  });

  return (
    <OptionChainContext.Provider
      value={{
        optionChains,
        setOptionChains,
        fetchAndSetOptionChains,
        updateOptionChains,
        setActiveOptionChainId,
        activeOptionChainId,
        initialOptionChain,
      }}
    >
      {children}
    </OptionChainContext.Provider>
  );
};

// Custom hook to use the OptionChainContext
export const useOptionChain = () => {
  return useContext(OptionChainContext);
};
