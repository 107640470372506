export const calculateDifferences = (list1, list2) => {
    const isObject1 = typeof list1 === 'object' && !Array.isArray(list1);
    const isObject2 = typeof list2 === 'object' && !Array.isArray(list2);
  
    const keys1 = isObject1 ? Object.keys(list1) : list1;
    const keys2 = isObject2 ? Object.keys(list2) : list2;
  
    const newItems = keys1.filter((item) => !keys2.includes(item));
    const removedItems = keys2.filter((item) => !keys1.includes(item));
  
    if (isObject1) {
      // If input is an object, return the full key-value pairs for newItems
      return {
        newItems: newItems.reduce((acc, key) => {
          acc[key] = list1[key];
          return acc;
        }, {}),
        removedItems, // Removed items can still be just keys
      };
    }
  
    // If input is not an object, return the keys as before
    return { newItems, removedItems };
  };
  