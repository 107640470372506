import React, { useEffect } from 'react';
import { Box, Paper, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import WindowControls from './WindowControls';
import { Resizable } from 're-resizable';

const StyledPaper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  overflow: 'hidden',
});

const HeaderBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  borderBottom: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const ContentBox = styled(Box)({
  flexGrow: 1,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const WindowComponent = ({ title, controlElements, children }) => {
  const theme = useTheme();
  return (
    <Resizable
      id="my-resizable"
      defaultSize={{ width: '100%', height: '100%' }}
      style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      enable={{
        top: true,
        right: true,
        bottom: true,
        left: true,
        topRight: true,
        bottomRight: true,
        bottomLeft: true,
        topLeft: true,
      }}
    >
      <StyledPaper style={{ backgroundColor: theme.palette.background.paper }}>
        <HeaderBox theme={theme}>
          <Typography variant="h6">{title}</Typography>
          <WindowControls>{controlElements}</WindowControls>
        </HeaderBox>
        <ContentBox>{children}</ContentBox>
      </StyledPaper>
    </Resizable>
  );
};

export default WindowComponent;
