import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select, MenuItem } from '@mui/material';

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <Select
      value={i18n.language}
      onChange={(e) => changeLanguage(e.target.value)}
      displayEmpty
      size="small"
      style={{ minWidth: 90 }} // Set a minimum width for the dropdown
    >
      <MenuItem value="en">English</MenuItem>
      <MenuItem value="zh">中文</MenuItem>
      {/* Additional languages can be added here */}
    </Select>
  );  
}

export default LanguageSwitcher;
