export const completedOrders = [6, 7, 8, 17, 19, 21];

export function mapOrdersUpdate(data, enumList) {
  // Ensure data is in the expected format.
  if (!data) {
    console.error('Invalid data format');
    return;
  }

  // Transform the incoming data to match the column dataKey structure
  const transformedData = {
    // Direct mappings where dataKey and received data key are the same
    cancel: completedOrders.includes(data.order_status) ? false : true,
    userId: data.user_id,
    cStrategy: data.c_strategy,
    remarks: data.remarks || '',
    submitTime: data.submit_time,
    status: enumList.order_status?.[data.order_status] || '',
    errorMsg: data.non_exec_reason,
    relatedStCntrOrderId: '',
    lastFillUpdateTime: data.last_updated_time,
    orderId: data.client_order_id,
    exchange: data.exchange_name,
    commodity: data.contract_name,
    date: data.contract_date,
    side: enumList.order_side?.[data.order_side] || '',
    lotsRatio: '', // Not directly available, may need additional logic
    type: enumList.order_type?.[data.order_type] || '',
    traderAccount: data.trader_account,
    amtFilled: data.amount_filled,
    nonExecReason: data.non_exec_reason || '',
    lots: data.lots,
    workingLots: data.lots - data.amount_filled,
    price1: data.price_1,
    price2: data.price_2,
    submitPrice: data.price_1,
    filledPrice: data.average_price, // Assuming average_price is filled price
    expiry: data.good_till_date,
    oc: enumList.open_close?.[data.open_close] || '',
    autoCancelTime: '', // Not directly available
    triggerCondition: '', // Not directly available
    autoClosePrice: '', // Not directly available
    hedgeFlag: enumList.hedge_flag?.[data.hedge_flag] || '',
    fokFak: data.fok_fak || '',
    rootOrderId: '', // Not directly available
    voidPrice: '', // Not directly available
    voidLots: '', // Not directly available
    priceFixing: data.is_fixed_price ? 'Yes' : 'No',
    upstreamOC: enumList.open_close?.[data.upstream_open_close] || '',
    lastFillTime: '', // Assuming last_updated_time is last fill time
    // Additional mappings for fields not listed but present in your data
    pendingClearPrice: '', // Not directly available
    pendingClearLots: '', // Not directly available
  };

  // Call the update function with the transformed data
  return transformedData;
}
