import { getDB } from '../db/indexedDB/indexedDBUtils'; // Ensure this path is correct
import { http, ws } from './config';
import globalConfig from './config';

export const loadConfigFromDB = async () => {
  try {
    const db = await getDB(); // Ensure getDB handles the singleton pattern properly
    const transaction = db.transaction(['tables'], 'readonly');
    const store = transaction.objectStore('tables');
    const request = store.get('networkTableId'); // Key for the configuration object

    return new Promise((resolve, reject) => {
      request.onsuccess = () => {
        if (request.result && request.result.rows.length > 0) {
          const ip = request.result.rows[0].ip;
          const port = request.result.rows[0].port;

          const updatedConfig = {
            baseURL: `${http}://${ip}:${port}`, // Always use HTTPS
            webSocketURL: `${ws}://${ip}:${port}/ws`, // Always use WSS
          };

          if (ip && port) {
            globalConfig.update(updatedConfig); // Assuming this method will merge or replace existing config
          }
        }
        console.log(globalConfig);
        resolve(globalConfig); // Resolve with the loaded/updated configuration
      };

      request.onerror = () => {
        console.error(
          'Error loading configuration from IndexedDB:',
          request.error
        );
        reject(request.error);
      };
    });
  } catch (error) {
    console.error('Failed to initialize or access IndexedDB:', error);
    throw error; // Rethrow the error to be caught by the caller if necessary
  }
};
