import React, { useState, useRef, useCallback, useEffect } from 'react';
import ReusableDialog from '../dialog/ReusableDialog';
import OrderEntryPanel from '../orderPanel/OrderEntryPanel';
import { useTheme } from '@mui/material/styles';

const OrderDialog = ({
  isOpen,
  amendOrderRef,
  actionType,
  title = 'New Order',
}) => {
  const [titleBgColor, setTitleBgColor] = useState(null);
  const theme = useTheme();
  const [toConfirm, setToConfirm] = useState(false);

  // Update the background color and the dialog content when the `amendOrderRef` changes
  useEffect(() => {
    if (isOpen && amendOrderRef.current) {
      setTitleBgColor(
        String(amendOrderRef.current.order_side) === '0'
          ? theme.palette.secondary.main
          : theme.palette.secondary.red
      );
    }
  }, [amendOrderRef.current, theme, isOpen, setTitleBgColor]);

  // Clean up `toAmend` when the dialog closes
  useEffect(() => {
    if (!isOpen) {
      setToConfirm(false);
    }
  }, [isOpen]);

  const onConfirm = useCallback(() => {
    setToConfirm(true);
  }, [toConfirm]);

  return (
    <>
      {isOpen && amendOrderRef.current && (
        <ReusableDialog
          title={title}
          titleBgColor={titleBgColor}
          onConfirm={onConfirm}
        >
          {/* Content of the dialog goes here */}
          <OrderEntryPanel
            actionType={actionType}
            orderInfo={amendOrderRef.current}
            toConfirm={toConfirm}
          />
        </ReusableDialog>
      )}
    </>
  );
};

export default OrderDialog;
