import React, { createContext } from 'react';
import { useTranslation } from 'react-i18next';

// Create a Context
export const TranslationContext = createContext(null);

// Create a Provider Component
export const TranslationProvider = ({ children }) => {
  const { t, i18n } = useTranslation();

  return (
    <TranslationContext.Provider value={{ t, i18n }}>
      {children}
    </TranslationContext.Provider>
  );
};
