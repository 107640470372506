// indexedDBUtils.js
import { schemas, dbVersion } from './schemaConfig';

const dbName = 'myAppDB'; // Database name
let dbInstance = null; // This will hold the database instance once opened

// Function to open the IndexedDB connection
const openDB = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, dbVersion);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;

      schemas.forEach((schema) => {
        if (!db.objectStoreNames.contains(schema.name)) {
          const store = db.createObjectStore(schema.name, {
            keyPath: schema.keyPath,
          });
          schema.indexes.forEach((index) => {
            store.createIndex(index.name, index.keyPath, {
              unique: index.unique,
            });
          });
        }
      });
    };

    request.onerror = (event) => {
      reject(event.target.errorCode);
    };

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };
  });
};

const checkCurrentDBVersion = (dbName) => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName);

    request.onsuccess = (event) => {
      const db = event.target.result;
      const currentVersion = db.version;
      console.log(`Current database version: ${currentVersion}`);
      db.close(); // Always close the database when you're done
      resolve(currentVersion);
    };

    request.onerror = (event) => {
      console.error('Error opening database:', event.target.errorCode);
      reject(event.target.errorCode);
    };

    request.onupgradeneeded = (event) => {
      // This block will not execute unless you specify a version higher than the current version.
      console.log('Upgrade needed, but we are checking current version.');
    };
  });
};

// Singleton pattern to get the database instance
export const getDB = async () => {
  if (!dbInstance) {
    dbInstance = await openDB();
    // Usage example
    checkCurrentDBVersion(dbName)
      .then((version) => {
        console.log(`The database is currently at version ${version}`);
      })
      .catch((error) => {
        console.error('Failed to check the database version:', error);
      });
  }
  return dbInstance;
};

// Consider adding a function to handle database version updates if schemas are changed
export const updateDBVersion = (newVersion) => {
  dbVersion = newVersion; // This sets the new version for upcoming openDB calls
  return openDB(); // This call will trigger the onupgradeneeded event if the version is indeed new
};

export const upsertDataBatch = async (db, storeName, dataList) => {
  // Start a readwrite transaction on the specified store
  const transaction = db.transaction([storeName], 'readwrite');
  const store = transaction.objectStore(storeName);

  // Wrap the whole transaction in a promise to handle completion and errors
  return new Promise((resolve, reject) => {
    const results = []; // Array to collect the results of each 'put' operation

    // Loop through dataList and perform a put operation for each item
    dataList.forEach((dataItem) => {
      const request = store.put(dataItem);
      request.onsuccess = () => {
        results.push(request.result); // Collect results of each operation
      };
      request.onerror = () => {
        console.error('Error upserting data: ', request.error);
        reject(request.error); // Reject if any request fails
      };
    });

    transaction.oncomplete = () => {
      console.log('updated database');
      resolve(results); // Resolve with all results when the transaction completes
    };
    transaction.onerror = transaction.onabort = () => {
      reject(transaction.error); // Reject if the transaction fails
    };
  });
};

export const deleteDataBatch = async (db, storeName, keys) => {
  // Start a readwrite transaction on the specified store
  const transaction = db.transaction([storeName], 'readwrite');
  const store = transaction.objectStore(storeName);

  // Map each key to a delete operation
  const promises = keys.map((key) => {
    return new Promise((resolve, reject) => {
      const request = store.delete(key);
      request.onsuccess = () => resolve(key); // Resolves with the key of the deleted record
      request.onerror = (event) => reject(request.error); // Rejects on error with the error message
    });
  });

  try {
    // Use Promise.all to execute all delete operations concurrently
    const results = await Promise.all(promises);

    // Wait for the transaction to complete successfully
    transaction.oncomplete = () => console.log('Transaction completed.');
    transaction.onerror = () =>
      console.error('Transaction failed:', transaction.error);

    return results; // Returns the keys of the successfully deleted records
  } catch (error) {
    console.error('Error deleting records:', error);
    throw error; // Re-throw error to be handled by the caller
  }
};

export const fetchDataByKeys = (db, storeName, keys = []) => {
  if (!db) return Promise.reject('Database not initialized');
  const transaction = db.transaction([storeName], 'readonly');
  const store = transaction.objectStore(storeName);

  // Check if keys are provided and not empty
  if (keys && keys.length > 0) {
    // Map each key to a promise that resolves when the data for that key is fetched
    const requests = keys.map(
      (key) =>
        new Promise((resolve, reject) => {
          const request = store.get(key);
          request.onsuccess = () => resolve(request.result);
          request.onerror = () => reject(request.error);
        })
    );

    // Use Promise.all to wait for all fetch operations to complete
    return Promise.all(requests);
  } else {
    // Fetch all records if no keys are provided
    return new Promise((resolve, reject) => {
      const request = store.getAll();
      request.onsuccess = () => {
        if (request.result.length === 0) {
          console.warn('No records found in the store');
        } else {
          console.log('Fetched records:', request.result);
        }
        resolve(request.result);
      };
      request.onerror = () => {
        console.error('Error fetching all records:', request.error);
        reject(request.error);
      };
    });
  }
};
