import React from 'react';
import { useError } from './ErrorContext';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

const ErrorModal = () => {
  const { errors, clearError } = useError();

  // If there are no errors, do not render anything
  if (errors.length === 0) {
    return null;
  }

  const currentError = errors[0];
  const isLastError = errors.length === 1;

  return (
    <Dialog
      open={true}
      onClose={() => clearError(0)}
      aria-labelledby="error-dialog-title"
      aria-describedby="error-dialog-description"
    >
      <DialogTitle id="error-dialog-title">{'An Error Occurred'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="error-dialog-description">
          {currentError.errorCode}
          <br />
          {currentError.errorMsg}
          {currentError.errorAction && (
            <>
              <br />
              {currentError.errorAction}
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => clearError(0)} color="primary" autoFocus>
          {isLastError ? 'Close' : `Next (${errors.length - 1} left)`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorModal;
