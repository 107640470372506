export const tradeInfoFields = [
  {
    items: [{ span: 12, col: { key: 'lastInfo', label: '', editable: false } }],
  },

  {
    items: [
      { span: 6, col: { key: 'change', label: '', editable: false } },
      { span: 6, col: { key: 'changePercent', label: '', editable: false } },
    ],
  },
];
