// src/routes/Routes.js
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from '../pages/LoginPage';
import Dashboard from '../pages/dashboard/Dashboard';
import { useAuth } from '../contexts/AuthContext';
import MarketDepth from '../components/marketDepth/MarketDepth';

const AppRoutes = () => {
  const { isAuthenticated } = useAuth(); // Assuming useAuth() provides this flag

  // A simple protected route component
  const ProtectedRoute = ({ children }) => {
    if (!isAuthenticated) {
      // User is not authenticated, redirect to login page
      return <Navigate to="/login" />;
    }
    return children;
  };

  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route path="/mkt-depth" element={<MarketDepth />} />
      {/* Redirect user to Dashboard if they are authenticated, otherwise to login */}
      <Route
        path="/"
        element={<Navigate to={isAuthenticated ? '/dashboard' : '/login'} />}
      />
      {/* Fallback for 404 Not Found */}
      <Route
        path="*"
        element={<Navigate to={isAuthenticated ? '/dashboard' : '/login'} />}
      />
    </Routes>
  );
};

export default AppRoutes;
