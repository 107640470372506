// ErrorContext.js
import React, { createContext, useContext, useState } from 'react';
import { getErrorMessage } from './ErrorUtils';

const ErrorContext = createContext({
  error: null,
  setError: () => {},
});

export const ErrorProvider = ({ children }) => {
  const [errors, setErrors] = useState([]);

  const handleError = (eCode, eMsg = '', eAction) => {
    const errorMsg = getErrorMessage(eCode, eMsg);
    const e = {
      errorCode: eCode,
      errorMsg: errorMsg,
      errorAction: eAction,
    };
    addError(e); // Set the error message that triggers the modal
    console.log(errorMsg); // or set this message in a state to display in the UI
  };

  const addError = (error) => {
    setErrors((prevErrors) => [...prevErrors, error]);
  };

  const clearError = (index) => {
    setErrors((prevErrors) => prevErrors.filter((_, i) => i !== index));
  };

  return (
    <ErrorContext.Provider value={{ errors, handleError, clearError }}>
      {children}
    </ErrorContext.Provider>
  );
};

export const useError = () => useContext(ErrorContext);
