import React, { useState, useRef, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import Watchlist from '../Watchlist';
import Options from '../Options';
import { useAuth } from '../../contexts/AuthContext';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useWebSocket } from '../../contexts/WebSocketContext';
import Fills from '../Fills';
import Orders from '../orders/Orders';
import MetricsDisplay from './MetricsDisplay';
import { ListItemButton } from '@mui/material';
import Settings from '../Settings';
import DeblurIcon from '@mui/icons-material/Deblur';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import StreamIcon from '@mui/icons-material/Stream';
import RefreshIcon from '@mui/icons-material/Refresh'; // Import the Refresh icon
import { loadAllData } from '../../utils/preLoaders';
import { useMetadata } from '../../contexts/MetadataContext';
import { useWindow } from '../../contexts/WindowContext ';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'fixed',
    height: '100vh',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const Dashboard = () => {
  const theme = useTheme(); // Use the theme hook here
  const { logout } = useAuth();
  const { webSocketInstance } = useWebSocket();
  const [currentView, setCurrentView] = useState('dashboard');
  const [open, setOpen] = useState(true);
  const appBarRef = useRef(null);
  const { loadProducts, loadEnumList } = useMetadata();
  const { closeAllWindows } = useWindow();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const changeView = (view) => {
    setCurrentView(view);
  };

  const handleLogout = () => {
    if (webSocketInstance && webSocketInstance.disconnect) {
      webSocketInstance.disconnect();
    }
    logout();
    closeAllWindows({ includeMainWindow: false });
  };

  const refreshData = async () => {
    await loadAllData([loadProducts(), loadEnumList()]);
  }; // Call the function to refresh the data

  const mainListItems = (
    <div>
      <ListItemButton onClick={() => changeView('watchlist')}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Watchlist" />
      </ListItemButton>
      <ListItemButton onClick={() => changeView('options')}>
        <ListItemIcon>
          <DeblurIcon />
        </ListItemIcon>
        <ListItemText primary="Options" />
      </ListItemButton>
      <ListItemButton onClick={() => changeView('orders')}>
        <ListItemIcon>
          <StreamIcon />
        </ListItemIcon>
        <ListItemText primary="Orders" />
      </ListItemButton>
      <ListItemButton onClick={() => changeView('fills')}>
        <ListItemIcon>
          <LibraryBooksIcon />
        </ListItemIcon>
        <ListItemText primary="Fills" />
      </ListItemButton>
      <ListItemButton onClick={() => changeView('settings')}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Settings" />
      </ListItemButton>
      <ListItemButton onClick={handleLogout}>
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </div>
  );

  const renderCurrentView = () => {
    switch (currentView) {
      case 'watchlist':
        return <Watchlist />;
      case 'options':
        return <Options />;
      case 'orders':
        return <Orders />;
      case 'fills':
        return <Fills />;
      case 'settings': // Add this case for the Settings page
        return <Settings />;
      default:
        return <div>Select an option from the sidebar</div>;
    }
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh', overflow: 'hidden' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} ref={appBarRef}>
        <Toolbar sx={{ pr: '24px' }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            Dashboard
          </Typography>
          <MetricsDisplay />
          <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>

          <IconButton
            color="inherit"
            onClick={() => {
              // Your logic to refresh or reload data goes here
              refreshData(); // Call the function to refresh the data
            }}
          >
            <RefreshIcon /> {/* Replace NotificationsIcon with RefreshIcon */}
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">{mainListItems}</List>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          overflow: 'hidden', // Prevent overflow in the main box
          display: 'flex',
          flexDirection: 'column',
          padding: theme.spacing(0.5),
          height: `calc(100vh - ${appBarRef.current ? appBarRef.current.clientHeight : 0}px)`,
          marginTop: `64px`, // Adjust this to match AppBar height
          marginLeft: `${open ? drawerWidth : parseInt(theme.spacing(7), 10)}px`, // Adjust based on drawer width
        }}
      >
        {renderCurrentView()}
      </Box>
    </Box>
  );
};

export default Dashboard;
