// src/pages/Options.js
import React from 'react';
import WindowComponentV2 from '../components/windows/WindowComponentV2';
import useDataSaver from '../db/indexedDB/useDataSaver';
import { workSpaceSaver, tabsSaver } from '../db/indexedDB/DBsaverMapper';

const workspaceId = 'tradex_optionChain';
const workspaceLabel = 'optionChain';
const tabsId = 'optionChain_tabs';
const tabContents = [{ componentId: 4 }];

const Options = () => {
  const saveIdsObj = {
    [workSpaceSaver]: [workspaceId],
    [tabsSaver]: [tabsId],
  };

  useDataSaver(saveIdsObj);

  return (
    <>
      <WindowComponentV2
        workspaceId={workspaceId}
        workspaceLabel={workspaceLabel}
        tabsId={tabsId}
        tabContents={tabContents}
      />
    </>
  );
};

export default Options;
