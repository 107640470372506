import React, { useState, useRef, useEffect } from 'react';
import Popover from '@mui/material/Popover';
import TreeComponent from './TreeComponent';
import TextField from '@mui/material/TextField';

const TreeDropdown = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const dropdownRef = useRef(null);

  const handleFocus = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    // Add logic to filter your tree based on the search query
  };

  const handleClickOutside = (event) => {
    // Ignore clicks on the backdrop or inside the TextField input
    if (event.target.classList.contains('MuiBackdrop-root')) {
      return;
    }
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? 'tree-search-popover' : undefined;

  return (
    <div>
      <TextField
        id="tree-search-field"
        variant="outlined"
        label="Search"
        onFocus={handleFocus}
        onChange={handleSearchChange}
        size="small"
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        disableAutoFocus // Prevents the Popover from taking focus away
      >
        <div ref={dropdownRef}>
          <TreeComponent searchQuery={searchQuery} />
        </div>
      </Popover>
    </div>
  );
};

export default TreeDropdown;
