import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import {
  Button,
  TextField,
  Typography,
  Container,
  AppBar,
  Toolbar,
  IconButton,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ThemeProvider } from '@mui/material/styles';
import { useMetadata } from '../contexts/MetadataContext';
import LanguageSwitcher from '../components/general/LanguageSwitcher';
import EnvironmentSelect from '../components/general/EnvironmentSelect';
import { useTranslation } from '../hooks/useTranslation';
import NetworkSettingsModal from '../components/networkingsettings/NetworkSettingsModal';
import SettingsIcon from '@mui/icons-material/Settings';
import createMyTheme from '../styles/theme';
import { useWebSocket } from '../contexts/WebSocketContext';
import { useNavigate } from 'react-router-dom';
import { useError } from '../errorHandler/ErrorContext';
import { loadAllData } from '../utils/preLoaders';
import { useOrders } from '../contexts/OrderContext';

export default function LoginPage() {
  const [loginId, setLoginId] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // For toggling password visibility
  const { login, loginSuccess, setLoginSuccess } = useAuth();
  const { loadProducts, loadEnumList } = useMetadata();
  const [open, setOpen] = useState(false);
  const { handleError } = useError();
  const { t, i18n } = useTranslation();
  const { setIsToConnect, setIsLoading, isLoading } = useWebSocket();
  const { isOrderInitializing } = useOrders();
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState(null);

  const theme = createMyTheme('light', i18n.language);

  useEffect(() => {
    if (loginSuccess && !isOrderInitializing) {
      setIsLoading(false);
      navigate('/dashboard');
    }
  }, [loginSuccess, isOrderInitializing]);

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await login(loginId, password);
      await loadAllData([loadProducts(), loadEnumList()]);
      setLoginSuccess(true);
      setIsToConnect(true);
    } catch (err) {
      setIsLoading(false);
      setLoginError(err.error_message);
      handleError(err.error_code, err.error_message, err.action_message);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="static" color="default" elevation={0}>
        <Toolbar>
          <div style={{ flexGrow: 1 }}></div>{' '}
          {/* This pushes everything to the right */}
          <div style={{ marginRight: 16 }}>
            <EnvironmentSelect />
          </div>
          <div style={{ marginRight: 16 }}>
            <LanguageSwitcher />
          </div>
          <div>
            <IconButton onClick={handleOpen}>
              <SettingsIcon />
            </IconButton>
          </div>
          <NetworkSettingsModal open={open} onClose={handleClose} />
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('Sign in')}
          </Typography>
          {loginError && (
            <Typography color="error" sx={{ mt: 2 }}>
              {loginError}
            </Typography>
          )}
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="loginId"
              label={t('Login ID')}
              name="loginId"
              autoComplete="username"
              autoFocus
              value={loginId}
              onChange={(e) => setLoginId(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={t('Password')}
              type={showPassword ? 'text' : 'password'}
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={toggleShowPassword}
                    edge="end"
                    sx={{ fontSize: 20 }}
                  >
                    {showPassword ? (
                      <VisibilityOff sx={{ fontSize: 20 }} />
                    ) : (
                      <Visibility sx={{ fontSize: 20 }} />
                    )}
                  </IconButton>
                ),
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isLoading}
            >
              {isLoading ? `${t('Signing In')}...` : t('Sign In')}
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
