import { createTheme } from '@mui/material/styles';

const fontSize = 14;

const fontFamilies = {
  en: 'Roboto, Arial, sans-serif',
  zh: 'Heiti SC, Microsoft YaHei, Noto Sans CJK SC, Arial, sans-serif',
};

// Helper function to get the appropriate font family based on the current language
const getFontFamily = (lng) =>
  lng.startsWith('zh') ? fontFamilies.zh : fontFamilies.en;

// Theme settings for each mode
const themeConfigurations = {
  light: {
    palette: {
      mode: 'light',
      primary: { main: '#556cd6' },
      secondary: { main: '#f50057', red: '#d32f2f' },
      background: { default: '#ffffff', paper: '#f4f5f7' },
      text: { primary: '#2e2e2e', secondary: '#575757' }, // Disabled text color for light mode,
      action: {
        hover: 'rgba(0, 0, 0, 0.04)',
        selected: 'rgba(0, 0, 0, 0.08)',
        disabledBackground: '#f0f0f0', // Disabled background color for light mode
      },
    },
  },
  dark: {
    palette: {
      mode: 'dark',
      primary: { main: '#262f3d' },
      secondary: { main: '#556cd6', red: '#d32f2f' },
      background: { default: '#1c1e23', paper: '#2b2e36' }, // Adjusted to be lighter
      text: { primary: '#ffffff', secondary: '#adb0bb' }, // Disabled text color for dark mode },
      action: {
        hover: 'rgba(255, 255, 255, 0.1)',
        selected: 'rgba(255, 255, 255, 0.2)',
        disabledBackground: '#5a5f67', // Disabled background color for dark mode
      },
    },
  },
  highContrast: {
    palette: {
      mode: 'dark',
      primary: { main: '#ff0000' },
      secondary: { main: '#00ff00' },
      background: { default: '#000000', paper: '#333333' },
      text: { primary: '#ffffff', secondary: '#ffff00' },
      action: {
        hover: 'rgba(255, 255, 255, 0.2)',
        selected: 'rgba(255, 255, 255, 0.3)',
      },
    },
  },
};

// Function to create a theme dynamically based on mode and language
const createMyTheme = (mode, lng) => {
  const fontFamily = getFontFamily(lng);
  const themeSettings = themeConfigurations[mode] || themeConfigurations.light; // Default to light if mode is undefined

  return createTheme({
    ...themeSettings,
    typography: {
      fontFamily: fontFamily,
      fontSize: fontSize, // Base font size for all themes
      h1: { fontSize: '2rem' },
      h5: {
        fontSize: '1.25rem', // Typically smaller, used for less important headers
      },
      h6: { fontSize: '1rem' },
      body1: { fontSize: '0.8rem' },
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: themeSettings.palette.background.paper,
            boxShadow: 'none',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: { fontFamily: fontFamily },
        },
      },
      // MuiIconButton: {
      //   styleOverrides: {
      //     root: {
      //       // Apply any specific styles you want for all IconButtons here
      //       padding: 5, // Adjust padding, for example
      //       color: themeSettings.palette.text.primary, // Set the icon color to match the primary color
      //     },
      //   },
      // },
      MuiTable: {
        styleOverrides: {
          root: {
            backgroundColor: themeSettings.palette.background.paper,
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontFamily,
            borderColor: 'rgba(0, 0, 0, 0.12)',
          },
          head: {
            backgroundColor: themeSettings.palette.background.paper,
            color: themeSettings.palette.text.primary,
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            '&:nth-of-type(odd)': {
              backgroundColor: themeSettings.palette.background.default,
            },
            '&:nth-of-type(even)': {
              backgroundColor: themeSettings.palette.background.paper,
            },
          },
        },
      },

      MuiTab: {
        styleOverrides: {
          root: { fontFamily: fontFamily },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            padding: 0,
            boxShadow: 'none', // Remove shadow
            '&.Mui-expanded': {
              margin: 0, // Remove margin when expanded
            },
            '&:before': {
              display: 'none', // Remove the default border line
            },
          },
        },
      },

      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: '12px',
            color: '#ffffff', // Ensure the text color is bright and readable
            minWidth: '600px', // Custom width for the dialog
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            color: '#ffffff', // White text for better visibility
            fontSize: '1.5rem',
            marginBottom: 20, // Padding at the bottom only
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: '16px 24px',
            justifyContent: 'flex-end',
          },
        },
      },
    },
  });
};

export const lightPrimaryTextColor = themeConfigurations.light.palette.text.primary;

export default createMyTheme;
