// MetricsDisplay.js
import React from 'react';
import { Typography } from '@mui/material';
import { useConnectionMetrics } from '../../hooks/useConnectionMetrics';

const MetricsDisplay = () => {
  const metrics = useConnectionMetrics();
  const rtt = metrics?.result?.rtt ?? '';
  const jitter = metrics?.result?.jitter ?? '';
  const packetLoss = metrics?.result?.packetLoss ?? '';
  const connectionState = metrics?.result?.connectionState ?? '';

  return (
    <Typography variant="body2" color="inherit" sx={{ marginRight: '15px' }}>
      RTT: {rtt} ms | Jitter: {jitter} ms | Packet Loss: {packetLoss}% |
      Connectoin: {connectionState}
    </Typography>
  );
};

export default MetricsDisplay;
