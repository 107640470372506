// DBContext.js
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react';
import {
  getDB,
  upsertDataBatch,
  deleteDataBatch,
  fetchDataByKeys,
} from './indexedDBUtils';
import { useError } from '../../errorHandler/ErrorContext';

const DBContext = createContext(null);

export const DBProvider = ({ children }) => {
  const [db, setDb] = useState(null);
  const { handleError } = useError();

  useEffect(() => {
    getDB()
      .then((db) => {
        setDb(db);
      })
      .catch((error) => {
        handleError(1001, error.message); // Use handleError to display error messages
      });
  }, []);

  // Wrapped CRUD operations
  const upsertRows = (storeName, rows) => {
    if (rows.length === 0) {
      return; // If there are no rows to upsert, exit the function
    }
    return upsertDataBatch(db, storeName, rows);
  };

  const deleteRows = (storeName, keys) => {
    return deleteDataBatch(db, storeName, keys);
  };

  const selectRows = (storeName, keys) => {
    return fetchDataByKeys(db, storeName, keys);
  };

  return (
    <DBContext.Provider
      value={{
        db,
        upsertRows,
        deleteRows,
        selectRows,
      }}
    >
      {children}
    </DBContext.Provider>
  );
};

export const useDB = () => useContext(DBContext);
