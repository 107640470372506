import React, { useMemo, useEffect } from 'react';
import VTable from '../tables/VTable';
import { useMarketData } from '../../contexts/MktDataContext';
import { mapMarketDataDOMUpdate } from '../../services/websocket/marketdata/MarketDataMapper';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { lightPrimaryTextColor } from '../../styles/theme';

const tableId = 'marketDepthTable'; // Unique table ID for Market Depth

const MarketDepth = ({ contract: propContract }) => {
  const location = useLocation();

  // Determine the contract: either from props or from the URL parameters
  const propsString = new URLSearchParams(location.search).get('props');
  const urlContract = JSON.parse(propsString)?.contract;

  const contract = propContract || urlContract;

  const {
    marketDataDOM,
    subscribeToMarketDataDOM,
    unsubscribeFromMarketDataDOM,
    marketData,
  } = useMarketData();

  // Subscribe to market data when the component mounts, and unsubscribe when it unmounts
  useEffect(() => {
    console.log(window.name);
    const handleBeforeUnload = () => {
      if (contract) {
        unsubscribeFromMarketDataDOM([contract]);
      }
    };
    if (contract) {
      // Subscribe to market data when the component mounts
      subscribeToMarketDataDOM([contract], mapMarketDataDOMUpdate);

      // Add beforeunload event listener to unsubscribe when the window is closed
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    // Unsubscribe when the component unmounts
    return () => {
      if (contract) {
        unsubscribeFromMarketDataDOM([contract]);
        window.removeEventListener('beforeunload', handleBeforeUnload); // Remove the event listener
      }
    };
  }, [contract, subscribeToMarketDataDOM, unsubscribeFromMarketDataDOM]);
  // Memoize column definitions
  const columnDefs = useMemo(
    () => [
      {
        field: 'epiq',
        headerName: 'EPIQ',
        width: 80,
        filter: false,
        suppressHeaderMenuButton: true,
      },
      {
        field: 'bv',
        headerName: 'Bids',
        cellClass: 'bids-cell',
        width: 100,
        cellStyle: { backgroundColor: '#98c8ff' },
        filter: false,
        suppressHeaderMenuButton: true,
      },
      {
        field: 'p',
        headerName: 'Price',
        cellStyle: { backgroundColor: '#f0f0f0' },
        width: 100,
        filter: false,
        suppressHeaderMenuButton: true,
        isRowId: true,
      },
      {
        field: 'ov',
        headerName: 'Asks',
        cellClass: 'asks-cell',
        width: 100,
        cellStyle: { backgroundColor: '#ff9999' },
        filter: false,
        suppressHeaderMenuButton: true,
      },
      {
        field: 'ltq',
        headerName: 'LTQ',
        width: 80,
        filter: false,
        suppressHeaderMenuButton: true,
      },
    ],
    []
  );

  // Base config for the VTable (if applicable)
  const baseConfig = useMemo(
    () => ({
      autoSizeMode: 'sizeColumnsToContentStrategy',
      cols: columnDefs,
      autoSave: false, // Disable autosave unless needed
      fontColor: lightPrimaryTextColor,
    }),
    [columnDefs]
  );

  // if (!marketDataDOM[contract]?.newRows) {
  //   console.log('No market data available for contract:', contract); // Log if no data is available
  //   return <div>Loading Market Data...</div>; // Render a loading state if no data
  // }

  if (!marketDataDOM) {
    return;
  }

  return (
    <Box
      sx={{
        height: '100vh', // Full viewport height
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto', // Allow scrolling if content overflows
      }}
    >
      <VTable
        tableId={tableId}
        insertRows={marketDataDOM?.[contract]?.newRows || []}
        updateRows={marketDataDOM?.[contract]?.updateRows || []}
        config={baseConfig}
        centerId={marketDataDOM?.[contract]?.centerId || ''}
      />
    </Box>
  );
};

export default MarketDepth;
