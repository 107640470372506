// Constants for WebSocket events
const ORDER_ADD_EVENT = 'user:trade_order:outright:add';
const ORDER_AMEND_EVENT = 'user:trade_order:outright:amend';
const ORDER_CANCEL_EVENT = 'user:trade_order:outright:cancel';

// Function to add a new order
export const addOrder = (webSocketService, token, orderData) => {
  const message = {
    event: ORDER_ADD_EVENT,
    data: orderData,
    token: token,
  };

  webSocketService.send(JSON.stringify(message));
};

// Function to amend an existing order
export const amendOrder = (webSocketService, token, orderData) => {
  const message = {
    event: ORDER_AMEND_EVENT,
    data: orderData,
    token: token,
  };

  webSocketService.send(JSON.stringify(message));
};

// Function to cancel an order

export const cancelOrders = (webSocketService, cxlIds) => {
  cxlIds.forEach((id) => {
    const cxlOrderMessage = {
      event: ORDER_CANCEL_EVENT,
      data: {
        client_order_id: id,
      },
      token: webSocketService.token,
    };

    // Send the cancellation message through WebSocket
    webSocketService.send(JSON.stringify(cxlOrderMessage));
  });
};
