export class DirectWebSocket {
  constructor(url, onMetricsUpdate) {
    this.url = url;
    this.onMetricsUpdate = onMetricsUpdate;
    this.listeners = {}; // Holds listeners for different message types
    this.pingInterval = null;
    this.pingMessagesSent = 0;
    this.pingMessagesReceived = 0;
    this.pingRoundTripTimes = [];
    this.initializeWebSocket();
  }

  initializeWebSocket() {
    this.socket = new WebSocket(this.url);

    this.socket.onopen = () => {
      console.log('WebSocket connection established');
      this.startPingPong();
    };

    this.socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === 'pong') {
        this.handlePong(data.timestamp);
      } else {
        // Dispatch the message to listeners based on its type
        const listeners = this.listeners[data.type] || [];
        listeners.forEach((listener) => listener(data));
      }
    };

    this.socket.onerror = (error) => {
      console.error('WebSocket Error:', error);
    };

    this.socket.onclose = () => {
      console.log('WebSocket Closed');
      this.stopPingPong();
    };
  }

  addListener(type, callback) {
    if (!this.listeners[type]) {
      this.listeners[type] = [];
    }
    this.listeners[type].push(callback);
  }

  removeListener(type, callback) {
    if (this.listeners[type]) {
      this.listeners[type] = this.listeners[type].filter(
        (listener) => listener !== callback
      );
    }
  }

  startPingPong() {
    this.pingInterval = setInterval(() => {
      this.send({ type: 'ping', timestamp: Date.now() });
      this.pingMessagesSent++;
    }, 5000); // Send a ping message every 5 seconds
  }

  handlePong(sentTimestamp) {
    const rtt = Date.now() - sentTimestamp;
    this.pingRoundTripTimes.push(rtt);
    this.pingMessagesReceived++;
    this.calculateAndReportMetrics();
  }

  calculateAndReportMetrics() {
    const rtt =
      this.pingRoundTripTimes[this.pingRoundTripTimes.length - 1] || 0;
    const jitter = this.calculateJitter();
    const packetLoss =
      ((this.pingMessagesSent - this.pingMessagesReceived) /
        this.pingMessagesSent) *
      100;

    // Report metrics back to the application
    if (this.onMetricsUpdate) {
      this.onMetricsUpdate({ rtt, jitter, packetLoss });
    }
  }

  calculateJitter() {
    if (this.pingRoundTripTimes.length < 2) return 0;

    let jitter = 0;
    for (let i = 1; i < this.pingRoundTripTimes.length; i++) {
      jitter += Math.abs(
        this.pingRoundTripTimes[i] - this.pingRoundTripTimes[i - 1]
      );
    }
    return jitter / (this.pingRoundTripTimes.length - 1);
  }

  send(data) {
    if (this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify(data));
    }
  }

  disconnect() {
    if (this.socket) {
      this.stopPingPong();
      this.socket.close();
    }
  }

  stopPingPong() {
    if (this.pingInterval) {
      clearInterval(this.pingInterval);
      this.pingInterval = null;
    }
  }
}
