import React, { useState, useEffect, useMemo, useRef } from 'react';
import OptionsDropdown from '../dropdown/OptionsDropdown';
import ContractsDropdown from '../dropdown/ContractsDropdown';
import CustomGrid from '../grids/CustomGrid';
import VTable from '../tables/VTable';
import { useMarketData } from '../../contexts/MktDataContext';
import { mapMarketDataUpdate } from '../../services/websocket/marketdata/MarketDataMapper';
import Grid from '@mui/material/Grid';
import { underlyingFields } from './optionChainCols';
import { optionChainConfig } from './optionChainConfig';
import { useOptionChain } from '../../contexts/OptionChainContext';
import { optionChainSaver } from '../../db/indexedDB/DBsaverMapper';
import useDataSaver from '../../db/indexedDB/useDataSaver';
import { useMetadata } from '../../contexts/MetadataContext';
import { Box } from '@mui/material';
import { calculateDifferences } from '../../utils/filterHelper';

const OptionChain = ({ optionChainId }) => {
  const underlyingContractsRef = useRef(null);
  const totalOptions = useRef({});
  const totalContracts = useRef([]);
  const [initializedData, setInitializedData] = useState([]);
  const hasInitialized = useRef(false);
  const latestUnderlyingMarketDataRef = useRef({});
  const [removeRows, setRemoveRows] = useState([]);
  const tOptionPricesRef = useRef({});
  const previousOptionsPriceRef = useRef([]);

  const {
    marketData,
    subscribeToMarketData,
    unsubscribeFromMarketData,
    subOptionsGreekCal,
    unsubOptionsGreekCal,
    tshapeOptions,
  } = useMarketData();
  const {
    optionChains,
    fetchAndSetOptionChains,
    setOptionChains,
    setActiveOptionChainId,
    initialOptionChain,
  } = useOptionChain();
  const { optionProducts, productContracts, loadContracts, formatOptionData } =
    useMetadata(); // Assuming this is an object

  // Set the active option chain ID and fetch initial data if not already loaded
  useEffect(() => {
    setActiveOptionChainId(optionChainId);
    if (!(optionChainId in optionChains)) {
      fetchAndSetOptionChains([optionChainId]).then(() => {
        // Using a callback to check the updated state
        setOptionChains((prev) => {
          if (!(optionChainId in prev)) {
            const selectedProduct = '';
            const selectedContract = '';
            return {
              ...prev,
              [optionChainId]: initialOptionChain(
                optionChainId,
                selectedProduct,
                selectedContract
              ),
            };
          }
          return prev;
        });
      });
    }
  }, [optionChainId]);

  // Update underlying contracts when the selected product or contract changes
  useEffect(() => {
    const optionChain = optionChains[optionChainId];

    if (optionChain?.selectedProduct && optionChain?.selectedContract) {
      const combinedValue = `${optionChain.selectedProduct}\\${optionChain.selectedContract}`;
      // Check if combinedValue is different from the previous contract
      if (combinedValue !== underlyingContractsRef.current) {
        // If there was a previous contract, unsubscribe from it
        if (underlyingContractsRef.current) {
          unsubscribeFromMarketData([underlyingContractsRef.current]); // Unsubscribe from the previous contract
        }

        // Subscribe to the new contract
        subscribeToMarketData([combinedValue], mapMarketDataUpdate);

        // Update the ref to store the current contract for future comparison
        underlyingContractsRef.current = combinedValue;
      }
    }
  }, [optionChains[optionChainId]]); // Only re-run effect when optionChainId's data changes

  // Prepare the array of option products
  const optionProductArray = useMemo(() => {
    return Object.keys(optionProducts)
      .sort() // Sort the keys alphabetically
      .map((key) => ({
        key: key, // Use the sorted key for both value and label
        details: optionProducts[key], // This retains all the details for each product
      }));
  }, [optionProducts]); // Recalculate only when optionProducts changes

  // Fetch and process contract data based on the selected product
  useEffect(() => {
    const fetchAndProcessContractData = async () => {
      const selectedProduct = optionChains[optionChainId]?.selectedProduct;
      if (!selectedProduct) return [];

      const options = optionProducts[selectedProduct];
      if (!options) return [];

      const productIdsToLoad = options
        .filter((item) => item.id && !(item.id in productContracts))
        .map((item) => item.id);

      // Load new contracts if necessary
      if (productIdsToLoad.length > 0) {
        await loadContracts(productIdsToLoad);
      }

      // No need to set sortedContracts here, as it's handled by useMemo
    };

    fetchAndProcessContractData();
  }, [optionChains[optionChainId]?.selectedProduct, productContracts]);

  // Function to convert and sort contracts by contract_term
  const convertAndSortContracts = (options, productContracts) => {
    // Group contracts by contract_term
    const groupedContracts = options
      .flatMap((option) =>
        Object.entries(productContracts[option.id] || {}).flatMap(
          ([key, contract]) => (contract ? { ...contract, key } : [])
        )
      )
      .reduce((acc, contract) => {
        const { contract_term } = contract;
        if (!acc[contract_term]) {
          acc[contract_term] = [];
        }
        acc[contract_term].push(contract);
        return acc;
      }, {});

    // Convert and sort contracts
    return Object.entries(groupedContracts)
      .map(([contract_term, options]) => ({
        contract_term,
        options,
      }))
      .sort((a, b) => a.contract_term.localeCompare(b.contract_term));
  };

  // Convert and sort contracts for the selected product
  const sortedContracts = useMemo(() => {
    const selectedProduct = optionChains[optionChainId]?.selectedProduct;
    if (!selectedProduct) return [];

    const options = optionProducts[selectedProduct];
    if (!options) return [];

    return convertAndSortContracts(options, productContracts);
  }, [optionChains[optionChainId]?.selectedProduct, productContracts]);

  const selectedOptions = useMemo(() => {
    hasInitialized.current = false;
    const foundContract = sortedContracts.find(
      (contract) =>
        contract.contract_term === optionChains[optionChainId]?.selectedContract
    );

    if (foundContract) {
      return foundContract.options.reduce((acc, option) => {
        const optionData = formatOptionData(option, option.key);
        acc[option.key] = optionData; // Assigning key-value pair to the object
        return acc;
      }, {});
    } else {
      return {}; // or whatever default value you want
    }
  }, [
    sortedContracts,
    optionChains[optionChainId]?.selectedContract,
    formatOptionData,
  ]);

  // Handle changes in contractList
  useEffect(() => {
    const { newItems: newContracts, removedItems: removedContracts } =
      calculateDifferences(
        Object.keys(selectedOptions),
        totalContracts.current
      );

    // Process new contracts
    if (newContracts.length > 0) {
      // Map newContracts to their corresponding selectedOptions
      const selectedNewOptions = newContracts.map(
        (contract) => selectedOptions[contract]
      );
      // Check if filteredContracts has items
      const initializedMarketData = tshapeOptions(selectedNewOptions, true);
      setInitializedData(Object.values(initializedMarketData));

      hasInitialized.current = true;
      totalContracts.current = [...totalContracts.current, ...newContracts];
      subscribeToMarketData(newContracts, mapMarketDataUpdate);
    }

    // Process removed contracts
    if (removedContracts.length > 0) {
      unsubscribeFromMarketData(removedContracts);

      const removeRows = removedContracts.map((contract) => ({
        strike: totalOptions.current?.[contract]?.strikePrice,
      }));

      // Use a Set to filter out duplicate strike values
      const uniqueRemoveRows = Array.from(
        new Map(removeRows.map((item) => [item.strike, item])).values()
      );

      setRemoveRows(uniqueRemoveRows);

      totalContracts.current = totalContracts.current.filter(
        (contract) => !removedContracts.includes(contract)
      );
    }
  }, [
    selectedOptions,
    tshapeOptions,
    subscribeToMarketData,
    unsubscribeFromMarketData,
  ]);

  // Handle changes in optionList
  useEffect(() => {
    const { newItems: newOptionsKeys, removedItems: removedOptions } =
      calculateDifferences(
        Object.keys(selectedOptions),
        Object.keys(totalOptions.current)
      );

    // Prepare newOptions object by collecting the data for the new keys
    const newOptions = newOptionsKeys.reduce((acc, key) => {
      acc[key] = selectedOptions[key];
      return acc;
    }, {});

    // Process new options
    if (Object.keys(newOptions).length > 0) {
      // Merge the new options into totalOptions.current
      totalOptions.current = {
        ...totalOptions.current,
        ...newOptions, // Add new options
      };

      // Pass the new options data to subOptionsGreekCal
      subOptionsGreekCal(newOptions);
    }

    // Process removed options
    if (removedOptions.length > 0) {
      // Remove each key from totalOptions.current
      removedOptions.forEach((key) => {
        delete totalOptions.current[key];
      });

      // Pass the removed options to unsubOptionsGreekCal
      unsubOptionsGreekCal(removedOptions);
    }
  }, [selectedOptions]);

  const updatedOptionsPrice = useMemo(() => {
    // Check if hasInitialized is true
    if (!hasInitialized.current) {
      return [];
    }

    // Filter marketData by the keys in selectedOptions and return a list of values with additional fields
    const filteredMarketData = Object.keys(marketData)
      .filter((key) => selectedOptions[key]) // Only include keys that exist in selectedOptions
      .map((key) => ({
        ...marketData[key], // Spread the existing data from marketData
        strikePrice: selectedOptions[key].strikePrice, // Add 'strike' field
        optionType: selectedOptions[key].optionType,
      }));

    // If there's no filtered market data, return the previous value (memoization safeguard)
    if (filteredMarketData.length === 0) {
      return previousOptionsPriceRef.current;
    }

    // Process the filtered data using tshapeOptions
    const newOptions = tshapeOptions(filteredMarketData);

    // Step 1: Update tOptionPricesRef.current with the new options, maintaining previous keys
    Object.keys(newOptions).forEach((key) => {
      tOptionPricesRef.current[key] = {
        ...(tOptionPricesRef.current[key] || {}), // Merge with existing data if it exists
        ...newOptions[key], // Override with the new data
      };
    });

    // Step 2: Extract updated values
    const updatedValues = Object.keys(newOptions).map(
      (key) => tOptionPricesRef.current[key]
    );

    previousOptionsPriceRef.current = updatedValues; // Update the ref to store the new value
    return updatedValues;
  }, [marketData, selectedOptions]);

  useEffect(() => {
    if (marketData?.[underlyingContractsRef.current]) {
      latestUnderlyingMarketDataRef.current =
        marketData[underlyingContractsRef.current];
    }
  }, [marketData, underlyingContractsRef.current]);

  // auto save optionChain data into database
  const saveIdsObj = useMemo(
    () => ({
      [optionChainSaver]: [optionChainId],
    }),
    [optionChainId]
  ); // Memoize to avoid unnecessary re-renders

  useDataSaver(saveIdsObj);

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={2} sx={{ flexShrink: 0 }}>
        <Grid item xs={3}>
          <OptionsDropdown optionProductArray={optionProductArray} />
        </Grid>
        <Grid item xs={3}>
          <ContractsDropdown sortedContracts={sortedContracts} />
        </Grid>
      </Grid>

      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <CustomGrid
          config={underlyingFields}
          data={latestUnderlyingMarketDataRef.current}
          sx={{ flexShrink: 0 }}
        />
        <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
          <VTable
            config={optionChainConfig}
            insertRows={initializedData}
            updateRows={updatedOptionsPrice}
            tableId={optionChainId}
            deleteRows={removeRows}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default OptionChain;
