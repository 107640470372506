// src/contexts/LayoutContext.js
import React, { createContext, useState, useContext } from 'react';

const LayoutContext = createContext();

export const useLayout = () => useContext(LayoutContext);

export const LayoutProvider = ({ children }) => {
  const [mainBoxSize, setMainBoxSize] = useState({ width: 0, height: 0 });

  return (
    <LayoutContext.Provider value={{ mainBoxSize, setMainBoxSize }}>
      {children}
    </LayoutContext.Provider>
  );
};
