// src/utils/loaders.js
export const loadAllData = async (loaders) => {
  try {
    await Promise.all(loaders);
    console.log('All data loaded successfully');
  } catch (error) {
    console.error('Error loading data:', error);
    throw error; // Re-throw the error so it can be handled where this function is called
  }
};
