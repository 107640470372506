import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useDB } from '../db/indexedDB/DBContext';

const WorkspacesContext = createContext();

export const WorkspacesProvider = ({ children }) => {
  const { db, selectRows, upsertRows } = useDB(); // Using methods from DBContext
  const [workspaces, setWorkspaces] = useState({});
  const [currentWorkspace, setCurrentWorkspace] = useState('');
  // Create a persistent reference for the BroadcastChannel
  const workspaceChannelRef = useRef(null);

  const fetchAndSetWorkspaces = async (workspaceIds) => {
    if (db && workspaceIds.length > 0) {
      const fetchedArray = await selectRows('workspaces', workspaceIds);
      const fetchedWorkspaces = fetchedArray.reduce((acc, item) => {
        if (item && item.workspaceId) {
          acc[item.workspaceId] = item;
        }
        return acc;
      }, {});

      // Update the component's state
      setWorkspaces((prev) => ({ ...prev, ...fetchedWorkspaces }));
      // Post update via BroadcastChannel to all windows/tabs

      workspaceChannelRef.current.postMessage({
        type: 'WORKSPACES_UPDATE',
        payload: fetchedWorkspaces,
      });
    }
  };

  // Handle incoming messages via BroadcastChannel
  const handleIncomingMessage = useCallback((event) => {
    if (event.data.type === 'WORKSPACES_UPDATE') {
      const updatedWorkspaces = event.data.payload;
      setWorkspaces((prev) => ({ ...prev, ...updatedWorkspaces }));
    }
  }, []);

  // Set up BroadcastChannel when component mounts
  useEffect(() => {
    if (!workspaceChannelRef.current) {
      workspaceChannelRef.current = new BroadcastChannel('workspace_channel');
    }

    // Set up the onmessage listener
    workspaceChannelRef.current.onmessage = handleIncomingMessage;

    // Clean up BroadcastChannel when component unmounts
    return () => {
      if (workspaceChannelRef.current) {
        workspaceChannelRef.current.close();
        workspaceChannelRef.current = null;
      }
    };
  }, [handleIncomingMessage]);

  const initialWorkspaceState = (workspaceId, label, tabsId, windowProps) => ({
    workspaceId: workspaceId,
    label: label || 'New Workspace',
    tabsId: tabsId || '',
    windowProps: windowProps || {},
  });

  const updateWorkSpace = (workspaceIds, updates) => {
    const rows = workspaceIds
      .map((id) => updates[id])
      .filter((update) => update !== undefined);
    upsertRows('workspaces', rows);
  };

  return (
    <WorkspacesContext.Provider
      value={{
        workspaces,
        setWorkspaces,
        fetchAndSetWorkspaces,
        initialWorkspaceState,
        updateWorkSpace,
        setCurrentWorkspace,
        currentWorkspace,
      }}
    >
      {children}
    </WorkspacesContext.Provider>
  );
};

export const useWorkspaces = () => useContext(WorkspacesContext);
