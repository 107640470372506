import { IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel'; // Using Material UI's Cancel icon
import { useOrders } from '../../contexts/OrderContext';

const CancelCellRenderer = ({ params }) => {
  const { orders, setTodeleteOrders } = useOrders();
  const handleCancelClick = () => {
    setTodeleteOrders([orders.accumulatedOrders[params.node?.id]]);
  };

  return (
    <>
      <IconButton onClick={handleCancelClick}>
        <CancelIcon color="error" />
      </IconButton>
    </>
  );
};

export const processingColumns = [
  {
    headerName: 'CANCEL',
    field: 'cancel',
    cellRenderer: (params) => {
      return params.value === true ? (
        <CancelCellRenderer params={params} />
      ) : null;
    },
  },
  { headerName: 'C-STRATEGY', field: 'cStrategy' },
  { headerName: 'STATUS', field: 'status' },
  { headerName: 'ERROR MSG', field: 'errorMsg' },
  { headerName: 'RELATED ST', field: 'relatedSt' },
  { headerName: 'USER ID', field: 'userId' },
  { headerName: 'REMARKS', field: 'remarks' },
  {
    headerName: 'SUBMIT TIME',
    field: 'submitTime',
    sortable: true, // Enables sorting
    sort: 'desc', // Automatically sort this column in ascending order
    // comparator: (valueA, valueB) => {
    //   // Optional: If submitTime is a date, you can provide a custom comparator
    //   const dateA = new Date(valueA).getTime();
    //   const dateB = new Date(valueB).getTime();
    //   return dateA - dateB;
    // },
  },
  { headerName: 'ORDER ID', field: 'orderId', isRowId: true },
  { headerName: 'EXCHANGE', field: 'exchange' },
  { headerName: 'COMMODITY', field: 'commodity' },
  { headerName: 'DATE', field: 'date' },
  { headerName: 'SIDE', field: 'side' },
  { headerName: 'LOTS RATIO', field: 'lotsRatio' },
  { headerName: 'TYPE', field: 'type' },
  { headerName: 'PRICE1', field: 'price1' },
  { headerName: 'PRICE2', field: 'price2' },
  { headerName: 'LOTS', field: 'lots' },
  { headerName: 'AMT FILLED', field: 'amtFilled' },
  { headerName: 'FILLED PRICE', field: 'filledPrice' },
  { headerName: 'WORKING LOTS', field: 'workingLots' },
  { headerName: 'TRADER ACCOUNT', field: 'traderAccount' },
  { headerName: 'EXPIRY', field: 'expiry' },
  { headerName: 'O/C', field: 'oc' },
  { headerName: 'AUTO CANCEL TIME', field: 'autoCancelTime' },
  { headerName: 'TRIGGER CONDITION', field: 'triggerCondition' },
  { headerName: 'AUTO CLOSE PRICE', field: 'autoClosePrice' },
  { headerName: 'HEDGE FLAG', field: 'hedgeFlag' },
  { headerName: 'LAST FILL TIME', field: 'lastFillTime' },
  { headerName: 'FOK/FAK', field: 'fokFak' },
  { headerName: 'PRICE FIXING', field: 'priceFixing' },
  { headerName: 'UPSTREAM O/C', field: 'upstreamOC' },
  { headerName: 'VOID PRICE', field: 'voidPrice' },
  { headerName: 'VOID LOTS', field: 'voidLots' },
  { headerName: 'PENDING CLEAR PRICE', field: 'pendingClearPrice' },
  { headerName: 'PENDING CLEAR LOTS', field: 'pendingClearLots' },
];
