//src\services\metadata\Productlist.js
import globalConfig from '../../config/config';

// Function to get product list
export const getEnumList = async () => {
  // Retrieve the token from localStorage
  const token = localStorage.getItem('id_token');

  try {
    const response = await fetch(
      `${globalConfig.baseURL}/user/metadata/enum_mappings`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Use the JWT token from localStorage
          Authorization: token ? `JWT ${token}` : undefined,
        },
      }
    );

    if (!response.ok) {
      // It's a good practice to handle HTTP errors such as unauthorized access
      if (response.status === 401) {
        // Handle unauthorized access, maybe redirect to login or refresh the token
      }
      throw new Error('Network response was not ok');
    }

    const rsp = await response.json();
    return rsp.result; // This is your product list
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error);
    throw error; // You may want to handle this error differently
  }
};
