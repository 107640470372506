export const networkTableCols = [
  { headerName: 'ID', field: 'tableId', editable: false, isRowId: true },
  {
    headerName: 'IP',
    field: 'ip',
    editable: true,
    cellEditor: 'agTextCellEditor',
  },
  {
    headerName: 'Port',
    field: 'port',
    editable: true,
    cellEditor: 'agTextCellEditor',
  },
  { headerName: 'Ping', field: 'ping', editable: false },
];
