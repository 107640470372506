import React, { useState, useEffect } from 'react';
import { Typography, Paper, TextField } from '@mui/material';
import '../../styles/updownFlashAnimation.css';

const MemoizedCell = React.memo(({ label, value, onChange, isEditable }) => {
  const [flashClass, setFlashClass] = useState('');
  const [previousValue, setPreviousValue] = useState(value);

  useEffect(() => {
    let timeoutId;

    if (value !== previousValue) {
      setFlashClass(() => {
        if (value > previousValue) {
          return 'ag-flash-green';
        } else if (value < previousValue) {
          return 'ag-flash-red';
        } else {
          return ''; // No change, no flash
        }
      });

      // Update previous value to current value
      setPreviousValue(value);

      // Clear the flash class after the animation duration
      timeoutId = setTimeout(() => {
        setFlashClass('');
      }, 1500); // Assuming your animation duration is 1.5s
    }

    return () => clearTimeout(timeoutId);
  }, [value, previousValue]);

  return (
    <Paper
      style={{
        padding: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'center',
        boxShadow: 'none', // Remove any existing shadow
        backgroundColor: 'transparent', // Make the background transparent
      }}
      elevation={0} // Disable the elevation to remove shadow
      className={flashClass}
    >
      <Typography variant="caption" sx={{ fontSize: '0.75rem', color: 'gray' }}>
        {label}
      </Typography>
      {isEditable ? (
        <TextField
          variant="outlined"
          value={value || ''}
          onChange={onChange}
          fullWidth
        />
      ) : (
        <Typography>{value}</Typography>
      )}
    </Paper>
  );
});

export default MemoizedCell;
