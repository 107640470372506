import React, { useEffect, useState, useRef } from 'react';
import { Modal, Box, Button, IconButton } from '@mui/material';
import { useTranslation } from '../../hooks/useTranslation';
import { useTheme } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import VTable from '../tables/VTable';
import { useTable } from '../../contexts/TableContext';
import globalConfig, { ws, http } from '../../config/config';
import { networkTableConfig } from './networkConfig';

const createNewRow = (nextId) => ({
  tableId: String(nextId), // Assigning the next available ID
  ip: '',
  port: '',
  ping: '',
});

const networkTableId = 'networkTableId';

function NetworkSettingsModal(props) {
  const { open, onClose } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    tables,
    setTables,
    setActiveTableId,
    updateTable,
    addRowsToTable,
    resumeTablesFromDB,
    selectedTableRow,
  } = useTable();

  const [insertRows, setInsertRows] = useState([]);
  const [updateRows, setUpdateRows] = useState([]);
  const [deleteRows, setDeleteRows] = useState([]);
  const [tableIdsList, setTableIdsList] = useState([]); // Use a list (array) to track tableIds

  // Set the initial tableId when the modal opens and process current rows
  useEffect(() => {
    if (open) {
      setActiveTableId(networkTableId);

      // Populate tableIdsList with current tableIds and categorize rows
      const currentRows = tables[networkTableId]?.rows || [];
      const newInsertRows = [];
      const newUpdateRows = [];

      currentRows.forEach((row) => {
        if (!tableIdsList.includes(row.tableId)) {
          // New row, accumulate in newInsertRows and add to tableIdsList
          newInsertRows.push(row);
          setTableIdsList((prevList) => [...prevList, row.tableId]); // Add to list using array spread
        } else {
          // Existing row, accumulate in newUpdateRows
          newUpdateRows.push(row);
        }
      });

      // Set insertRows and updateRows all at once
      setInsertRows(newInsertRows);
      setUpdateRows(newUpdateRows);
    } else {
      setTableIdsList([]); // Reset tableIdsList when modal opens
      setInsertRows([]);
      setUpdateRows([]);
      setDeleteRows([]);
    }
  }, [open, setActiveTableId, tables]);

  const initialData = {
    rows: [createNewRow(1)],
    tableId: networkTableId,
  };

  useEffect(() => {
    setTables((prev) => {
      const isStillEmpty =
        !prev[networkTableId] || prev[networkTableId].rows?.length === 0;
      if (isStillEmpty) {
        return {
          ...prev,
          [networkTableId]: initialData,
        };
      }
      return prev; // No update needed if the condition is not met
    });
  }, [networkTableId, initialData, setTables]);

  const handleDelete = (index) => {
    if (selectedTableRow.networkTableId) {
      setTables((prevTables) => {
        const updatedRows = prevTables[networkTableId].rows.filter(
          (row) => row.tableId !== selectedTableRow.networkTableId.tableId
        );

        return {
          ...prevTables,
          [networkTableId]: {
            ...prevTables[networkTableId],
            rows: updatedRows,
          },
        };
      });
      setDeleteRows([selectedTableRow.networkTableId]);
    }
  };

  const handleConfirm = () => {
    // Implement confirmation logic
    updateTable([networkTableId], tables);
    updateConfig();
    onClose();
  };

  const updateConfig = () => {
    if (tables[networkTableId] && tables[networkTableId].rows.length > 0) {
      const ip = tables[networkTableId].rows[0].ip;
      const port = tables[networkTableId].rows[0].port;
      const updatedConfig = {
        baseURL: `${http}://${ip}:${port}/`, // Assuming HTTPS for production
        webSocketURL: `${ws}://${ip}:${port}/ws`, // Assuming WSS for production
      };
      if (ip && port) {
        globalConfig.update(updatedConfig); // Assuming this method will merge or replace existing config
      }

      console.log(globalConfig);
    }
  };

  const handleCancel = () => {
    resumeTablesFromDB([networkTableId]);
    onClose();
  };

  const handleAdd = () => {
    const currentRows = tables[networkTableId]?.rows || [];

    // Calculate the next index. If rows are empty, start from 1; otherwise, find the max existing id and add 1
    const nextId =
      currentRows.length === 0
        ? 1
        : Math.max(...currentRows.map((row) => row.tableId)) + 1;

    // Create a new row with the nextId
    const newRow = createNewRow(nextId);
    // Add the new row to the table
    addRowsToTable(networkTableId, [newRow]);
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: theme.palette.background.paper, // Use theme's background color
    padding: 1,
    fontFamily: theme.typography.fontFamily, // Use font family from theme
    width: '40vw', // Set width to be responsive
    height: '40vh', // Set a responsive height
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <VTable
          insertRows={insertRows}
          tableId={networkTableId}
          config={networkTableConfig}
          updateRows={updateRows}
          deleteRows={deleteRows}
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 2 }}>
          <IconButton onClick={handleAdd} color="primary">
            <AddIcon />
          </IconButton>
          <IconButton onClick={handleDelete} color="primary">
            <DeleteIcon />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 4 }}>
          <Button variant="contained" onClick={handleConfirm} sx={{ mr: 2 }}>
            {t('Confirm')}
          </Button>
          <Button variant="contained" onClick={handleCancel}>
            {t('Cancel')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default NetworkSettingsModal;
