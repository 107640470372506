export const WatchListComponentID = 3;
export const VTableComponentID = 2;
export const TreeDropdownComponentID = 1;
export const OptionChainComponentID = 4;

export const watchListProps = (tableId) => {
  return { tableId: tableId };
};

export const optionChainProps = (optionChainId) => {
  return { optionChainId: optionChainId };
};
