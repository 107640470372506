import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';

// Create the context
const WindowContext = createContext();

// Provider component to manage window state globally
export const WindowProvider = ({ children }) => {
  const [windows, setWindows] = useState({}); // Store windows as an object
  const windowsRef = useRef({});

  // Function to open a new window and render a component inside it

  const openWindow = useCallback((id, Component, props = {}, options = {}) => {
    const {
      title = 'New Window',
      width = 400,
      height = window.screen.availHeight, // Slightly less than full height to avoid OS limitations
      x = 100,
      y = 0,
      additionalContent,
      onOpen,
      onClose,
    } = options;

    // Open a new window
    const newWindow = window.open(
      `/mkt-depth?props=${JSON.stringify(props)}`, // Empty URL if you don't want to load a new page
      id,
      `width=${width},height=${height},left=${x},top=${y}`
    );

    // Use the 'load' event to ensure the new window's document is ready
    newWindow.addEventListener('load', () => {
      newWindow.name = id;

      // Inject styles from the main window into the new window
      // injectStyles(newWindow);

      // Dynamically set the window title
      newWindow.document.title = title;

      // Optionally inject additional content before rendering the component
      if (additionalContent) {
        const additionalEl = newWindow.document.createElement('div');
        additionalEl.innerHTML = additionalContent;
        newWindow.document.body.appendChild(additionalEl);
      }

      newWindow.focus();

      // Handle lifecycle: trigger onOpen when window is created
      if (onOpen) onOpen(newWindow);

      // Cleanup: handle window close event and lifecycle cleanup
      const cleanup = () => {
        newWindow.close();
        if (onClose) onClose();
        const { [id]: _, ...remainingWindows } = windowsRef.current;
        windowsRef.current = remainingWindows; // Update windowsRef
        setWindows(windowsRef.current); // Update state for any components relying on it
      };

      // Automatically clean up when the window is closed
      newWindow.onbeforeunload = cleanup;

      // Store the window reference as an object, keyed by window ID
      windowsRef.current[id] = {
        newWindow,
        cleanup,
        component: Component,
        props,
      };
      setWindows({ ...windowsRef.current }); // Update state for any components relying on it
    });

    // Return the container element reference for rendering the portal later
    return windowsRef.current[id];
  }, []);

  // Function to close a specific window by its ID
  const closeSingleWindow = useCallback((id) => {
    const windowRef = windowsRef.current[id];
    if (windowRef && windowRef.cleanup) {
      windowRef.cleanup();
    }
  }, []);

  // Function to close all windows, with an option to exclude the main window
  const closeAllWindows = useCallback((includeMainWindow = true) => {
    Object.values(windowsRef.current).forEach(({ newWindow, cleanup }) => {
      // Only close the sub-windows, not the main window if includeMainWindow is false
      if (includeMainWindow || newWindow !== window) {
        cleanup(); // Clean up each window
      }
    });

    // If includeMainWindow is true, close the main window as well
    if (includeMainWindow) {
      window.close(); // This will attempt to close the main window
    }

    windowsRef.current = {}; // Clear windows in the ref
    setWindows({}); // Clear windows in the state
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', closeAllWindows);

    return () => {
      window.removeEventListener('beforeunload', closeAllWindows);
    };
  }, [closeAllWindows]);

  // Provide functions and state to children
  return (
    <WindowContext.Provider
      value={{
        openWindow,
        closeSingleWindow,
        closeAllWindows,
        windows,
      }}
    >
      {children}
    </WindowContext.Provider>
  );
};

export const useWindow = () => useContext(WindowContext);
