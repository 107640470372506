import React, {
  useEffect,
  useMemo,
  useRef,
  useCallback,
  useState,
} from 'react';
import VTable from '../tables/VTable';
import WindowComponent from '../windows/WindowComponent';
import { useTable } from '../../contexts/TableContext';
import { useOrders } from '../../contexts/OrderContext';
import { mapOrdersUpdate } from '../../services/websocket/orders/OrderMapper';
import { processingColumns } from '../../pages/orders/OrderCols';
import { useMetadata } from '../../contexts/MetadataContext';

const completedOrders = [6, 7, 8, 17, 21];
const tableId = 'completedOrdersTable';
const ordersConfig = {
  autoSizeMode: 'sizeColumnsToContentStrategy',
  cols: processingColumns,
  autoSave: true,
  paginationPageSize: 200,
};

const CompletedOrders = () => {
  const { setActiveTableId } = useTable();
  const { orders } = useOrders();
  const isInitializedRef = useRef(false);
  const [orderGroups, setOrderGroups] = useState([]); // Replacing useRef with useState
  const { enumList } = useMetadata();

  useEffect(() => {
    setActiveTableId(tableId);
  }, [setActiveTableId]);

  const handleTableFocus = useCallback(() => {
    setActiveTableId(tableId);
  }, [setActiveTableId]);

  // Manage initial rows (iCRows) and inserted rows (insertedCRows)
  const iRows = useMemo(() => {
    let updatedOrders = [];

    if (!isInitializedRef.current && orders.accumulatedOrders && enumList) {
      isInitializedRef.current = true;
      updatedOrders = Object.values(orders.accumulatedOrders);
    } else if (isInitializedRef.current && orders.newOrder) {
      updatedOrders = [orders.newOrder];
    } else {
      return [];
    }

    const completedRows = updatedOrders
      .filter((order) => completedOrders.includes(order.order_status))
      .map((order) => {
        setOrderGroups((prevGroups) => [...prevGroups, order.client_order_id]); // Trigger re-render
        return mapOrdersUpdate(order, enumList);
      });

    return completedRows; // Return both initial and inserted rows
  }, [orders.accumulatedOrders, orders.newOrder, enumList]);

  // Manage updated, inserted, and removed rows (uCRows, iuCRows, rCRows)
  const [uRows, iuRows, rRows] = useMemo(() => {
    if (!orders.updateOrder || !enumList) return [[], [], []];

    const comingOrder = mapOrdersUpdate(orders.updateOrder, enumList);
    const orderId = comingOrder.orderId;

    let updatedRows = [],
      insertedRows = [],
      removedRows = [];

    // Check if the order needs to be updated, inserted, or removed
    if (completedOrders.includes(orders.updateOrder.order_status)) {
      if (orderGroups.includes(orderId)) {
        updatedRows.push(comingOrder);
      } else {
        insertedRows.push(comingOrder);
        setOrderGroups((prevGroups) => [...prevGroups, orderId]); // Trigger re-render
      }
    } else {
      if (orderGroups.includes(orderId)) {
        removedRows.push(comingOrder);
        setOrderGroups((prevGroups) =>
          prevGroups.filter((id) => id !== orderId)
        ); // Trigger re-render
      }
    }

    return [updatedRows, insertedRows, removedRows];
  }, [orders.updateOrder, enumList]);

  // Combine initial and inserted rows
  const combinedCRows = useMemo(() => {
    const rows = [...iRows, ...iuRows];
    return rows;
  }, [iRows, iuRows]);

  return (
    <div onClick={handleTableFocus} style={{ height: '100%', width: '100%' }}>
      <WindowComponent title="Completed Orders">
        <VTable
          tableId={tableId}
          insertRows={combinedCRows}
          updateRows={uRows}
          deleteRows={rRows}
          config={ordersConfig}
        />
      </WindowComponent>
    </div>
  );
};

export default CompletedOrders;
