import TreeDropdown from '../components/tree/TreeDropdown';
import VTable from '../components/tables/VTable';
import WatchList from '../components/watchlist';
import OptionChain from '../components/optionChain/OptionChain';
import {
  WatchListComponentID,
  VTableComponentID,
  TreeDropdownComponentID,
  OptionChainComponentID,
} from './compenentProps';

export const componentMapper = {
  0: null,
  [TreeDropdownComponentID]: TreeDropdown,
  [VTableComponentID]: VTable,
  [WatchListComponentID]: WatchList,
  [OptionChainComponentID]: OptionChain,
};
