// src/services/metadata/Contractlist.js
import globalConfig from '../../config/config';

export const getFills = async (currentUser) => {
  const token = currentUser.token;
  const traders = currentUser.user_details.trader_accs;
  const tders = { trader_accounts: traders };

  try {
    const response = await fetch(`${globalConfig.baseURL}/user/trade/fills`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? `JWT ${token}` : '', // Updated to Bearer as it's more commonly used with JWTs
      },
      body: JSON.stringify(tders), // Send the products array in the request body
    });

    if (!response.ok) {
      // Handle HTTP errors
      if (response.status === 401) {
        // Handle unauthorized access, maybe redirect to login or refresh the token
        // ... (redirect to login or refresh token logic)
      }
      // You could handle other HTTP status codes as needed
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const rsp = await response.json();

    return rsp.result; // Assuming the API returns a JSON object with a 'result' key that contains the contract list
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error);
    // Depending on your error handling strategy, you might want to re-throw the error, handle it here, or even return a default value
    throw error;
  }
};
