import { ToastContainer, toast } from 'react-toastify';
import { useOrders } from '../../contexts/OrderContext';
import { useEffect } from 'react';
import { useMetadata } from '../../contexts/MetadataContext';

function OrderNotification() {
  const { orders } = useOrders();
  const { enumList } = useMetadata();

  const handleOrderStatusToast = (newOrder, enumList) => {
    if (!newOrder || !enumList) return;
    console.log(newOrder);

    const clientOrderId = newOrder.client_order_id;
    const orderStatusCode = newOrder.order_status;
    const orderStatusText =
      enumList.order_status?.[orderStatusCode] || 'Unknown status';
    const errorMsg = newOrder.non_exec_reason || 'No error';

    // Determine the type of toast to show based on order_status
    if ([0, 2].includes(orderStatusCode)) {
      toast.info(
        `Order ID: ${clientOrderId}\nStatus: ${orderStatusText}\nError: ${errorMsg}`
      );
    } else if ([1, 4, 5, 7].includes(orderStatusCode)) {
      toast.warn(
        `Order ID: ${clientOrderId}\nStatus: ${orderStatusText}\nError: ${errorMsg}`
      );
    } else if ([8].includes(orderStatusCode)) {
      toast.error(
        `Order ID: ${clientOrderId}\nStatus: ${orderStatusText}\nError: ${errorMsg}`
      );
    } else if ([3, 6, 21].includes(orderStatusCode)) {
      toast.success(
        `Order ID: ${clientOrderId}\nStatus: ${orderStatusText}\nError: ${errorMsg}`
      );
    } else {
      toast(
        `Order ID: ${clientOrderId}\nStatus: ${orderStatusText}\nError: ${errorMsg}`
      ); // Default toast
    }
  };

  useEffect(() => {
    const comingOrder = orders?.newOrder || orders?.updateOrder;
    if (comingOrder) {
      handleOrderStatusToast(comingOrder, enumList);
    }
  }, [orders?.newOrder, orders?.updateOrder]);

  return (
    <ToastContainer
      style={{
        fontSize: '0.75rem', // Smaller font
        padding: '8px 12px', // Adjust the padding inside the toast
        minHeight: '40px', // Adjust the minimum height
      }}
      position="bottom-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      limit={4} // Limit to 4 visible toasts at a time
    />
  );
}

export default OrderNotification;
