import React from 'react';
import CustomTabs from '../components/tabs/CustomTabs';

const settingsWorkspaceId = 'settingsWorkspaceId';
const settingsTabsId = 'settingsTabsId';
const settingsTabContents = [{ componentId: 0 }];
const staticTabs = {
  Options: [{ componentId: 0 }],
  Trade: [{ componentId: 0 }],
};

const Settings = () => {
  return (
    <CustomTabs
      workspaceId={settingsWorkspaceId}
      tabsId={settingsTabsId}
      // tabContents={settingsTabContents}
      staticTabs={staticTabs}
    />
  );
};

export default Settings;
