// src/services/websocket/initWebSocketService.js
import { DirectWebSocket } from './DirectWebSocket';
import { SharedWorkerSocket } from './SharedWorkerSocket';
import globalConfig from '../../config/config';

const sharedWorkerPath = '/sharedWorker.js';
const isSharedWorkerSupported = !!window.SharedWorker;

let websocketInstance = null; // Singleton instance

// Function to initialize the WebSocket service based on the current configuration
function initializeWebSocketService(token) {
  if (!websocketInstance) {
    if (isSharedWorkerSupported) {
      websocketInstance = new SharedWorkerSocket(sharedWorkerPath, token);
    } else {
      websocketInstance = new DirectWebSocket(
        globalConfig.getConfig().webSocketURL
      );
    }
  }
  return websocketInstance;
}

export default initializeWebSocketService;
