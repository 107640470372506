export const watchListCols = [
  { field: 'symbol', headerName: 'SYMBOL', isRowId: true },
  { field: 'bidSize', headerName: 'BID SIZE', trackOldValue: true },
  { field: 'bid', headerName: 'BID', trackOldValue: true },
  { field: 'ask', headerName: 'ASK', trackOldValue: true },
  { field: 'askSize', headerName: 'ASK SIZE', trackOldValue: true },
  { field: 'last', headerName: 'LAST', trackOldValue: true },
  { field: 'lastSize', headerName: 'LAST SIZE', trackOldValue: true },
  { field: 'lastTime', headerName: 'LAST TIME' },
  { field: 'volume', headerName: 'VOLUME', trackOldValue: true },
  { field: 'matchVolume', headerName: 'MATCH VOLUME' },
  { field: 'change', headerName: 'CHANGE' },
  { field: 'changePercent', headerName: 'CHANGE, %' },
  { field: 'open', headerName: 'OPEN', trackOldValue: true },
  { field: 'high', headerName: 'HIGH', trackOldValue: true },
  { field: 'low', headerName: 'LOW', trackOldValue: true },
  { field: 'prevClose', headerName: 'PREV. CLOSE' },
  { field: 'expiryDate', headerName: 'EXPIRY DATE' },
  { field: 'description', headerName: 'DESCRIPTION' },
  { field: 'symbolType', headerName: 'SYMB. TYPE' },
  { field: 'lastUpdate', headerName: 'LAST UPDATE' },
  { field: 'symbolStatus', headerName: 'SYMBOL STATUS' },
  { field: 'prevSettlementPrice', headerName: 'PREVIOUS SETTLEMENT PRICE' },
  // Adding IV and Greeks columns
  { field: 'iv', headerName: 'IV', trackOldValue: true },
  { field: 'delta', headerName: 'DELTA', trackOldValue: true },
  { field: 'gamma', headerName: 'GAMMA', trackOldValue: true },
  { field: 'theta', headerName: 'THETA', trackOldValue: true },
  { field: 'vega', headerName: 'VEGA', trackOldValue: true },
  { field: 'rho', headerName: 'RHO', trackOldValue: true },

  // ... Add additional columns as necessary
];
