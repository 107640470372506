// unexpected error handler
import React from 'react';
import { getErrorMessage } from './ErrorUtils'; // Ensure this path is correct

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorMsg: '' };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render shows the fallback UI.
    return {
      hasError: true,
      error,
      errorMsg: getErrorMessage(1000, error.message),
    };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error to an error reporting service here if desired
    console.error('Uncaught error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Render a fallback UI with a more specific error message
      return (
        <div>
          <h1>Oops!</h1>
          <p>{this.state.errorMsg}</p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
