const MAX_INIT_TIME = 20000; // Maximum initialization time (20 seconds)
const BATCH_TIMEOUT = 500; // Time window to batch orders

export const initOrders = (incomingOrder, batchOrdersRef) => {
  const batchOrders = batchOrdersRef.current;
  const initStartTime = batchOrders.initStartTime || Date.now();

  // Add the incoming order to the batch
  batchOrders.orders.push(incomingOrder);

  // Set the start time only once at the beginning
  if (!batchOrders.initStartTime) {
    batchOrders.initStartTime = initStartTime;
  }

  // Check if maximum initialization time has passed
  if (Date.now() - initStartTime >= MAX_INIT_TIME) {
    console.log('Reached max initialization time (20 seconds).');
    const processedOrders = processBatchOrders(batchOrders.orders);
    batchOrders.orders = []; // Clear the batch
    batchOrders.initStartTime = '';
    return Promise.resolve(processedOrders); // Return the processed orders
  }

  // Return a promise to handle batching within 200ms window
  return new Promise((resolve) => {
    clearTimeout(batchOrders.timeout); // Clear the previous timeout
    batchOrders.timeout = setTimeout(() => {
      console.log('Processing batched orders after 200ms.');
      console.log(batchOrders.orders.length);
      const processedOrders = processBatchOrders(batchOrders.orders);
      batchOrders.orders = []; // Clear the batch
      batchOrders.initStartTime = '';
      resolve(processedOrders); // Resolve with the processed orders
    }, BATCH_TIMEOUT);
  });
};

// Helper function to process batched orders
const processBatchOrders = (batchOrders) => {
  return batchOrders.reduce((acc, order) => {
    acc[order.client_order_id] = order;
    return acc;
  }, {});
};
