// Helper for subscribing to market data
export const subscribeToMarketDataHelper = (webSocketService, contracts) => {
  const subscriptionMessage = {
    event: 'user:md_price:subscribe',
    data: { contract_id: contracts },
    token: webSocketService.token,
  };

  // Send the subscription message to WebSocket for batch contracts
  webSocketService.send(JSON.stringify(subscriptionMessage));
};

// Helper for unsubscribing from market data
export const unsubscribeFromMarketDataHelper = (
  webSocketService,
  contracts
) => {
  const unsubscriptionMessage = {
    event: 'user:md_price:unsubscribe',
    data: { contract_id: contracts },
    token: webSocketService.token,
  };

  // Send the unsubscription message to WebSocket for batch contracts
  webSocketService.send(JSON.stringify(unsubscriptionMessage));
};

export const subscribeToMarketDataDOMHelper = (webSocketService, contracts) => {
  const subscriptionMessage = {
    event: 'user:md_price:subscribe:dom',
    data: { contract_id: contracts },
    token: webSocketService.token,
  };

  // Register listener (only if not already added)
  if (!webSocketService.listeners['MarketDataDOMPrice']?.length) {
    console.log('Listener added for MarketDataDOMPrice in subscribe helper'); // Confirm addition in helper
  }

  // Send the subscription message to WebSocket for batch contracts
  webSocketService.send(JSON.stringify(subscriptionMessage));
};

// Helper for unsubscribing from market data
export const unsubscribeFromMarketDataDOMHelper = (
  webSocketService,
  contracts
) => {
  const unsubscriptionMessage = {
    event: 'user:md_price:unsubscribe:dom',
    data: { contract_id: contracts },
    token: webSocketService.token,
  };

  // Send the unsubscription message to WebSocket for batch contracts
  webSocketService.send(JSON.stringify(unsubscriptionMessage));
};
