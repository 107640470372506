//src\contexts\MetadataContext.js

// src/contexts/MetadataContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { getProductList } from '../services/metadata/Productlist';
import { getContractList } from '../services/metadata/Contractlist';
import { calculateYearsUntilExpiration } from '../utils/optionsCalculator';
import { getEnumList } from '../services/metadata/EnumList';
import { useDB } from '../db/indexedDB/DBContext';
import { isEmptyObj } from '../utils/general';
import { mainWindow } from '..';

const MetadataContext = createContext();
export const useMetadata = () => useContext(MetadataContext);

export const MetadataProvider = ({ children }) => {
  const [products, setProducts] = useState({});
  // Initialize contracts as an object to track contracts by productId
  const [productContracts, setProductContracts] = useState({});
  const [optionProducts, setOptionProducts] = useState({});
  const [enumList, setEnumList] = useState({});
  const { upsertRows, db, selectRows } = useDB(); // Using methods from DBContext

  const loadProducts = async () => {
    const products = await getProductList(); // Fetch the product data
    const convertedObjects = products.reduce(
      (acc, obj) => {
        const key = `${obj.exchange_name}\\${obj.product_name}`;
        const optionKey = `${obj.exchange_name}\\${obj.underlying_product_name}`;

        // Always store the product under 'products'
        acc.products[key] = obj;

        // Optionally store it under 'optionProducts' if it meets the criteria

        if (obj.product_name === 'ag_o') {
          console.log();
        }
        if (obj.product_type === 2) {
          obj['id'] = key;
          acc.optionProducts[optionKey] = acc.optionProducts[optionKey] || [];
          acc.optionProducts[optionKey].push(obj);
        }

        return acc;
      },
      { products: {}, optionProducts: {} }
    );

    const updateRows = [
      { dataType: 'product', dataObj: convertedObjects.products },
      { dataType: 'optionProduct', dataObj: convertedObjects.optionProducts },
    ];

    updateMetaData(updateRows);
    // Assume convertedObjects.products is your target object
    setProducts(convertedObjects.products);
    setOptionProducts(convertedObjects.optionProducts); // Assume a setter for optionProducts
  };

  const loadEnumList = async () => {
    const enumList = await getEnumList();
    const updateRows = [{ dataType: 'enumList', dataObj: enumList }];
    updateMetaData(updateRows);
    setEnumList(enumList);
  };

  const loadContracts = async (productIds) => {
    const contracts = await getContractList(productIds);

    // Transforming the fetched contracts data into the desired format
    const transformedContracts = {};
    for (let key in contracts) {
      if (!transformedContracts[key]) {
        transformedContracts[key] = {}; // Initialize if not already set
      }

      contracts[key].forEach((contract) => {
        const newKey = `${key}\\${contract.contract_date}`; // Create the new key

        // Assign the contract object to the new key and merge in products[key]
        transformedContracts[key][newKey] = {
          ...contract,
          ...products[key], // Merge product details directly into each contract
          // Optionally add any other properties here if needed
        };
      });
    }

    // Update the state with the transformed contracts
    // Update the state with the transformed contracts
    setProductContracts((prevContracts) => {
      const updateRows = [
        {
          dataType: 'productContract',
          dataObj: { ...prevContracts, ...transformedContracts },
        },
      ];
      updateMetaData(updateRows);
      return {
        ...prevContracts,
        ...transformedContracts,
      };
    });
  };

  const formatOptionData = (contractData) => {
    return {
      optionType: contractData.c_p === 1 ? 'C' : 'P',
      strikePrice: String(contractData.strike_price),
      freeRisk: 0.02,
      underlying: `${contractData.exchange_name}\\${contractData.underlying_product_name}\\${contractData.contract_term}`,
      expTime: calculateYearsUntilExpiration(contractData.expiry_date),
      symbol: contractData.symbol,
    };
  };

  const updateMetaData = (rows) => {
    if (rows.length > 0) {
      upsertRows('metaData', rows);
    }
  };

  const fetchAndSetMetaData = async (dataTypes) => {
    if (db && dataTypes.length > 0) {
      const fetchedArray = await selectRows('metaData', dataTypes);
      const fetchedMetaData = fetchedArray.reduce((acc, item) => {
        if (item && item.dataType) {
          if (item.dataType === 'product') {
            setProducts(item.dataObj);
          }
          if (item.dataType === 'enumList') {
            setEnumList(item.dataObj);
          }
          acc[item.dataType] = item;
        }
        return acc;
      }, {});
    }
  };

  useEffect(() => {
    const initializeData = async () => {
      // Make initializeData async
      if (
        (isEmptyObj(products) || isEmptyObj(enumList)) &&
        window.name !== mainWindow
      ) {
        const dataTypes = ['product', 'enumList'];
        await fetchAndSetMetaData(dataTypes); // Await the async function
      }
    };

    initializeData(); // Call the async function
  }, [products, enumList, db]); // Dependency array with products and enumList

  return (
    <MetadataContext.Provider
      value={{
        products,
        optionProducts,
        loadProducts,
        productContracts,
        loadContracts,
        formatOptionData,
        loadEnumList,
        enumList,
      }}
    >
      {children}
    </MetadataContext.Provider>
  );
};
