import React, { useState, useEffect, useMemo, useRef } from 'react';
import { FixedSizeList as List } from 'react-window';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTheme } from '@mui/material/styles';
import { useMetadata } from '../../contexts/MetadataContext';
import { useTable } from '../../contexts/TableContext';

export const CONTRACTDETAILS_KEY = 'contractDetails';

const dataFormatconvert = (data) => {
  const treeData = {};
  Object.entries(data).forEach(([key, { exchange_name, product_name }]) => {
    if (!treeData[exchange_name]) {
      treeData[exchange_name] = {
        symbol: exchange_name,
        name: exchange_name,
        isExchange: true,
        children: [],
        leaf: false,
      };
    }

    // Using productId as a key within children object
    treeData[exchange_name].children.push({
      symbol: key,
      name: product_name,
      isExchange: false,
      parent: exchange_name,
      children: [],
      leaf: false,
    });
  });

  // Return both the tree data and the productContracts
  return Object.values(treeData);
};

function flattenTreeWithSearch(
  nodes,
  expandedIds,
  contracts,
  searchQuery,
  enableExpandedIds = true
) {
  const final = [];
  const lowerSearchQuery = searchQuery.toLowerCase();

  const processNode = (node, level = 0, parentMatches = false) => {
    const childrenContracts = contracts[node.symbol] || {};
    const hasChildren =
      node.children.length > 0 || Object.keys(childrenContracts).length > 0;

    const nodeMatches = node.name.toLowerCase().includes(lowerSearchQuery);
    const includeNode = nodeMatches || parentMatches;
    const flattened = [];
    let childIncluded = false;

    // Process children first

    for (const child of node.children) {
      const childIncludedResult = processNode(child, level + 1, includeNode);
      if (childIncludedResult.length > 0) {
        childIncluded = true;
        //only if allow to add new symbol to expendIDs or including node.symol.
        //e.g., ag under SHFE, if now collapse SHFE, then we should not add ag to flatten list, but we still add SHFE into the flatten list.
        if (enableExpandedIds || expandedIds.includes(node.symbol)) {
          flattened.push(...childIncludedResult); // Push children nodes first
        }
      }
    }
    // If the node or any child matches the search query, include the node
    if (includeNode || childIncluded) {
      if (
        !expandedIds.includes(node.symbol) &&
        childIncluded &&
        enableExpandedIds
      ) {
        expandedIds.push(node.symbol);
      }
      flattened.unshift({ ...node, level, hasChildren }); // Push parent node after its children
    }

    // Include contracts if the node or parent matches (only in search mode)
    if (
      includeNode &&
      contracts[node.symbol] &&
      expandedIds.includes(node.symbol)
    ) {
      const sortedContracts = Object.entries(childrenContracts).sort(
        ([, a], [, b]) => new Date(a.contract_date) - new Date(b.contract_date)
      );
      sortedContracts.forEach(([contractId, contract]) => {
        flattened.push({
          symbol: contractId,
          name: contract.contract_date,
          level: level + 1,
          parent: node.symbol,
          isExchange: false,
          leaf: true,
          ...contract,
        });
      });
    }

    return flattened; // Return the flattened list for this node and its children
  };

  for (const node of nodes) {
    const nodeFlattened = processNode(node);
    final.push(...nodeFlattened);
  }

  return final;
}

// Ensure flattenTree properly handles possibly undefined contracts[node.symbol]
function flattenTreeWithoutSearch(nodes, expandedIds, contracts) {
  const flattened = [];
  const processNode = (node, level = 0) => {
    const childrenContracts = contracts[node.symbol] || {};
    const hasChildren =
      node.children.length > 0 || Object.keys(childrenContracts).length > 0;

    flattened.push({ ...node, level, hasChildren });

    if (expandedIds.includes(node.symbol)) {
      node.children.forEach((child) => processNode(child, level + 1));
      if (contracts[node.symbol]) {
        const sortedContracts = Object.entries(childrenContracts).sort(
          ([, a], [, b]) =>
            new Date(a.contract_date) - new Date(b.contract_date)
        );
        sortedContracts.forEach(([contractId, contract]) => {
          flattened.push({
            symbol: contractId,
            name: contract.contract_date,
            level: level + 1,
            parent: node.symbol,
            isExchange: false,
            leaf: true,
            ...contract,
          });
        });
      }
    }
  };
  nodes.forEach((node) => processNode(node));
  return flattened;
}

const TreeComponent = ({ searchQuery }) => {
  const theme = useTheme();
  const { products, loadContracts, productContracts } = useMetadata();
  const [expandedIds, setExpandedIds] = useState([]);
  const [hierarchicalData, setHierarchicalData] = useState([]);
  // Use useTable hook to get access to addContractsToTable and currentTableId
  const { addRowsToTable, activeTableId } = useTable();
  const previousSearchQuery = useRef(searchQuery);
  const [flattenedData, setFlattenedData] = useState([]);

  useEffect(() => {
    const convertedHierarchicalData = dataFormatconvert(products);
    setHierarchicalData(convertedHierarchicalData);
  }, [products]);

  useEffect(() => {
    // when searchquery is not changed, then shouldResetExpandedIds should be false.
    // e.g., when search, click expand or collapse does not change expandedIds.
    const shouldResetExpandedIds = previousSearchQuery.current !== searchQuery;
    const newExpandedIds = shouldResetExpandedIds ? [] : expandedIds;

    previousSearchQuery.current = searchQuery;

    let result;

    if (searchQuery) {
      // Handle search query present case
      result = flattenTreeWithSearch(
        hierarchicalData,
        newExpandedIds,
        productContracts,
        searchQuery,
        shouldResetExpandedIds
      );
    } else {
      // Handle search query cleared case
      result = flattenTreeWithoutSearch(
        hierarchicalData,
        newExpandedIds,
        productContracts
      );
    }

    setFlattenedData(result);

    // Update expandedIds if it was reset
    if (shouldResetExpandedIds) {
      setExpandedIds(newExpandedIds);
    }
  }, [hierarchicalData, expandedIds, productContracts, searchQuery]);

  const handleCollapse = (node) => {
    const collapseNodeAndChildren = (eachNode, expandedList) => {
      // Remove the node itself from the expanded list
      expandedList = expandedList.filter((id) => id !== eachNode.symbol);

      // Recursively remove all children and their descendants from the expanded list
      for (const childNode of eachNode.children) {
        expandedList = collapseNodeAndChildren(childNode, expandedList);
      }

      return expandedList;
    };

    setExpandedIds((prev) => collapseNodeAndChildren(node, prev));
  };

  const handleExpandClick = async (node) => {
    if (
      !node.isExchange &&
      !expandedIds.includes(node.symbol) &&
      !(node.symbol in productContracts)
    ) {
      await loadContracts([node.symbol]);
    }

    if (expandedIds.includes(node.symbol)) {
      handleCollapse(node);
    } else {
      setExpandedIds((prev) =>
        prev.includes(node.symbol)
          ? prev.filter((symbol) => symbol !== node.symbol)
          : [...prev, node.symbol]
      );
    }
  };

  const handleDoubleClick = (node) => {
    if (node.leaf) {
      addRowsToTable(activeTableId, [node]);
    }
  };

  const renderTreeItems = ({ index, style }) => {
    const node = flattenedData[index];
    return (
      <div
        style={{
          ...style,
          display: 'flex',
          alignItems: 'center',
          paddingLeft: `${20 * node.level}px`,
        }}
        onDoubleClick={() => handleDoubleClick(node)} // Double-click to add contracts
      >
        {!node.leaf && (
          <div
            onClick={(event) => handleExpandClick(node, event)}
            style={{ cursor: 'pointer' }}
          >
            {expandedIds.includes(node.symbol) ? (
              <ArrowDropDownIcon />
            ) : (
              <ArrowRightIcon />
            )}
          </div>
        )}
        <div style={{ marginLeft: !node.leaf ? 0 : 24, cursor: 'pointer' }}>
          {node.name}
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        height: 400,
        width: '100%',
        overflowX: 'hidden',
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <List
        height={400}
        width={300}
        itemCount={flattenedData.length}
        itemSize={35}
        itemData={flattenedData}
      >
        {renderTreeItems}
      </List>
    </div>
  );
};

export default TreeComponent;
