import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';

const Dropdown = ({
  label,
  items,
  valueKey = 'value', // Default valueKey
  labelKey = 'label', // Default labelKey
  value,
  onChange,
  sx = {}, // Accept sx prop for additional styling
  error = false, // Add error handling
  helperText = '', // Add helperText handling
  disabled = false, // Add disabled prop
}) => {
  const isEmpty = items.length === 0;

  return (
    <FormControl
      fullWidth
      focused={false}
      sx={sx}
      error={error}
      disabled={disabled}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        label={label}
        onChange={onChange}
        disabled={disabled}
      >
        {isEmpty ? (
          <MenuItem value={value} style={{ color: 'red' }}>
            {value || 'No available options'}
          </MenuItem>
        ) : (
          items.map((item) => {
            // Determine if the item is an object or a string
            const itemValue = typeof item === 'object' ? item[valueKey] : item;
            const itemLabel = typeof item === 'object' ? item[labelKey] : item;

            return (
              <MenuItem key={itemValue} value={itemValue}>
                {itemLabel}
              </MenuItem>
            );
          })
        )}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default Dropdown;
