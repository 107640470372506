import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react';
import {
  subscribeToMarketDataHelper,
  unsubscribeFromMarketDataHelper,
  subscribeToMarketDataDOMHelper,
  unsubscribeFromMarketDataDOMHelper,
} from '../services/websocket/marketdata/MarketDataService';
import { useWebSocket } from './WebSocketContext';
import { calculateOptions } from '../utils/optionsCalculator';
import { useMetadata } from './MetadataContext';
import { adjustToTickSize } from '../utils/numbers';

// Create MarketDataContext
const MarketDataContext = createContext(null);

// Custom hook to use the MarketDataContext
export const useMarketData = () => useContext(MarketDataContext);

// Provider component
export const MarketDataProvider = ({ children }) => {
  const accumulatedMarketDataRef = useRef({});
  const [marketData, setMarketData] = useState({});
  const [marketDataDOM, setMarketDataDOM] = useState({});
  const marketDataDOMRangeRef = useRef({});
  const contractRefs = useRef({}); // Track references to each contract using ref
  const contractDOMRefs = useRef({}); // Track references to each contract using ref
  const marketDataListener = useRef(null); // Store the listener function reference
  const marketDataDOMListener = useRef(null); // Store the listener function reference
  // Define refs to hold option inputs and underlyings
  const optionInputsRef = useRef({}); // Initial value is an empty object
  const underlyingsRef = useRef({}); // Initial value is an empty object
  const subscriptionCountRef = useRef({}); // Track subscription counts
  const [isCalOptionData, setIsCalOptionData] = useState({});
  const { products } = useMetadata();
  const centerPriceRef = useRef({});
  const seriesIdRef = useRef('');

  const { webSocketInstance } = useWebSocket();

  // Queue and timer refs for optionGreeks updates
  const updateQueue = useRef([]); // Queue to store optionGreeks updates
  const updateTimer = useRef(null); // Timer reference to handle 10ms updates

  // Function to schedule the batched update every `updateInterval`
  const scheduleUpdate = (updateInterval = 10) => {
    if (!updateTimer.current) {
      updateTimer.current = setInterval(() => {
        if (updateQueue.current.length) {
          // Temporary object to accumulate all updates at once
          const tempAccumulatedData = {};

          // Process all queued updates and accumulate them based on the `symbol` field
          updateQueue.current.forEach((optionGreeks) => {
            const symbol = optionGreeks.symbol; // Use symbol as the unique key

            // If the symbol already exists in accumulatedMarketDataRef, merge the fields
            const existingData = accumulatedMarketDataRef.current[symbol] || {};

            // Merge the existing data with the new updates from optionGreeks
            tempAccumulatedData[symbol] = {
              ...existingData, // Keep existing fields
              ...optionGreeks, // Overwrite with new updates
            };
          });

          // Apply all accumulated updates to the ref at once
          accumulatedMarketDataRef.current = {
            ...accumulatedMarketDataRef.current,
            ...tempAccumulatedData, // Merge new updates with the current accumulated data
          };

          // After processing all updates, update the state with the accumulated data
          setMarketData({ ...tempAccumulatedData });

          // Clear the queue after applying the updates
          updateQueue.current = [];
        }
      }, updateInterval);
    }
  };

  // Function to accumulate updates for each symbol and calculate option Greeks
  const accumulateUpdate = async (symbol, priceField, data) => {
    const prevOptionInput = optionInputsRef.current[symbol]; // Get the previous option data

    // Create a new option input with updated price field (optionPrice or underlyingPrice)
    const newInput = { ...prevOptionInput, [priceField]: data.ask };

    // Calculate Greeks asynchronously based on new option input
    if (newInput.optionPrice !== '' && newInput.underlyingPrice !== '') {
      const optionGreeks = await calculateOptions(newInput, 'CALCULATE_GREEKS');

      // Add the calculated Greeks to the queue for batching updates
      updateQueue.current.push(optionGreeks);
    }

    // Update the optionInputsRef with the new input data
    optionInputsRef.current[symbol] = newInput;

    // Schedule the update if not already scheduled
    scheduleUpdate();
  };

  // Main function to handle options data updates
  const updateOptionsData = async (data) => {
    // If the symbol is in optionInputsRef, update its optionPrice
    if (data.symbol in optionInputsRef.current) {
      await accumulateUpdate(data.symbol, 'optionPrice', data);
    }
    // Otherwise, update underlyingPrice for all associated options contracts in underlyingsRef
    else {
      const contractIds = underlyingsRef.current[data.symbol] || [];
      contractIds.forEach(async (contractId) => {
        await accumulateUpdate(contractId, 'underlyingPrice', data);
      });
    }
  };

  // Cleanup: clear the update timer when the component unmounts
  useEffect(() => {
    return () => {
      if (updateTimer.current) {
        clearInterval(updateTimer.current); // Clear the timer on unmount
      }
    };
  }, []); // Empty dependency array ensures this effect runs only on mount/unmount

  ////////////////////////////////////////////////////////////////////
  //
  //
  // Handle incoming market data updates
  const handleMarketDataUpdate = useCallback(
    (data, mapMarketData) => {
      const tableData = mapMarketData(data); // Map the incoming data
      // const keysToCheck = ['iv', 'delta', 'gamma', 'theta', 'vega', 'rho'];

      // Access the previous data before updating
      const previousData =
        accumulatedMarketDataRef.current[data.contract_id] || {};

      // Merge the new data (tableData) with the existing data (previousData)
      const updatedData = {
        ...previousData, // Keep the existing fields
        ...tableData, // Overwrite with new updates
      };

      // Accumulate the new data in accumulatedMarketDataRef
      accumulatedMarketDataRef.current = {
        ...accumulatedMarketDataRef.current,
        [data.contract_id]: updatedData, // Update the contract's data in the ref
      };

      setMarketData({ [data.contract_id]: updatedData });

      // Check if the symbol exists and if the 'ask' price has changed compared to previous data
      if (
        tableData.symbol in underlyingsRef.current || // Check in underlyingsRef
        (tableData.symbol in optionInputsRef.current &&
          tableData.ask !== previousData?.ask) // Compare with previous data
      ) {
        setIsCalOptionData(tableData); // Trigger calculation for option Greeks
      }
    },
    [underlyingsRef, optionInputsRef]
  );

  // Helper function to update contract reference count
  const updateContractRefs = useCallback((contracts, updater) => {
    const newRefs = { ...contractRefs.current };

    contracts.forEach((contract) => {
      const newCount = updater(newRefs[contract] || 0, contract); // Get the updated reference count

      if (newCount <= 0) {
        // Only delete if the contract is already in newRefs
        if (contract in newRefs) {
          delete newRefs[contract]; // Remove contract from contractRefs if count is 0
        }
      } else {
        newRefs[contract] = newCount; // Update the reference count
      }
    });

    contractRefs.current = newRefs; // Manually update the ref
  }, []);

  // Register a single market data listener
  const registerMarketDataListener = useCallback(
    (mapMarketData) => {
      if (!marketDataListener.current) {
        marketDataListener.current = (data) => {
          if (data.type === 'MarketDataPrice') {
            handleMarketDataUpdate(data.result, mapMarketData);
          }
        };
        webSocketInstance.addListener(
          'MarketDataPrice',
          marketDataListener.current
        );
      }
    },
    [handleMarketDataUpdate, webSocketInstance]
  );

  // Batch Subscribe to market data
  const subscribeToMarketData = useCallback(
    (contracts, mapMarketData) => {
      const contractsToSubscribe = [];

      updateContractRefs(contracts, (count, contract) => {
        if (count === 0) {
          contractsToSubscribe.push(contract);
        }
        return count + 1; // Increment contract reference count
      });

      if (contractsToSubscribe.length > 0) {
        // Register the market data listener centrally
        registerMarketDataListener(mapMarketData);
        // Subscribe to all unique contracts in a batch
        subscribeToMarketDataHelper(
          webSocketInstance,
          contractsToSubscribe // Pass the entire batch
        );
      }
    },
    [webSocketInstance, registerMarketDataListener, updateContractRefs]
  );

  // Reset market data for unsubscribed contracts using accumulatedMarketDataRef
  const resetMarketDataForUnsubscribedContracts = useCallback((contracts) => {
    // Update accumulatedMarketDataRef.current by removing the unsubscribed contracts
    contracts.forEach((contract) => {
      delete accumulatedMarketDataRef.current[contract]; // Remove the unsubscribed contract's data
    });
  }, []);

  // Batch Unsubscribe from market data
  const unsubscribeFromMarketData = useCallback(
    (contracts) => {
      const contractsToUnsubscribe = [];

      // Collect contracts that need to be unsubscribed
      updateContractRefs(contracts, (count, contract) => {
        if (count === 1) {
          contractsToUnsubscribe.push(contract);
        }
        return count - 1; // Decrement contract reference count
      });

      // Unsubscribe all collected contracts in a batch
      if (contractsToUnsubscribe.length > 0) {
        unsubscribeFromMarketDataHelper(
          webSocketInstance,
          contractsToUnsubscribe
        );
      }

      // If no contracts remain, remove the listener
      if (
        Object.keys(contractRefs.current).length === 0 &&
        marketDataListener.current
      ) {
        webSocketInstance.removeListener(
          'MarketDataPrice',
          marketDataListener.current
        );
      }

      resetMarketDataForUnsubscribedContracts(contractsToUnsubscribe);
    },
    [webSocketInstance, updateContractRefs]
  );

  // Initialize market data for new contracts
  const initializeMarketData = useCallback((contracts) => {
    const uniqueContractList = Array.from(new Set(contracts));

    // Map the new contracts to the initial market data structure
    const initializedMarketData = uniqueContractList.reduce((acc, contract) => {
      acc[contract] = {
        symbol: contract,
        last: '', // Default last price, add more fields as needed
      };
      return acc;
    }, {});

    return initializedMarketData;
  }, []);

  /////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  // Handle incoming market data updates
  const handleMarketDataDOMUpdate = useCallback(
    (data, mapMarketData) => {
      const {
        contract_id,
        bid_dom,
        last_incremental_price_ID,
        incremental_price_ID,
      } = data; // Destructure data for cleaner access
      if (contract_id in contractDOMRefs.current) {
        const productId = contract_id.split('\\').slice(0, 2).join('\\'); // Extract productId
        const tickSize = products[productId]?.tick_size; // Ensure tickSize exists
        const tableData = mapMarketData(data, tickSize); // Map the incoming data
        const newRows = [];

        if (seriesIdRef.current) {
          console.log(last_incremental_price_ID);
          if (last_incremental_price_ID !== seriesIdRef.current) {
            console.log('dom price error');
          } else {
            seriesIdRef.current = incremental_price_ID;
          }
        }

        // get centerpriceref
        if (bid_dom.length > 0 && bid_dom[0].v !== 0) {
          const maxPrice = Math.max(
            centerPriceRef.current[contract_id] || 0,
            bid_dom[0].p
          );
          centerPriceRef.current[contract_id] = adjustToTickSize(
            maxPrice,
            tickSize
          );
        }

        // Helper function to push new ranges only in descending order
        const pushNewRange = (start, end, step) => {
          for (let i = start; i >= end; i -= step) {
            newRows.push({ p: adjustToTickSize(i, tickSize) });
          }
        };

        // Get the current range for the product
        const currentRange = marketDataDOMRangeRef.current[contract_id];

        if (!currentRange) {
          const lowerBound =
            parseFloat(tableData[tableData.length - 1].p) - 100 * tickSize;
          const upperBound = parseFloat(tableData[0].p) + 100 * tickSize;

          marketDataDOMRangeRef.current[contract_id] = [lowerBound, upperBound];
          pushNewRange(upperBound, lowerBound, tickSize);
        } else {
          const [currentLower, currentUpper] = currentRange;

          // Update lower bound and push new lower values if extended
          if (parseFloat(tableData[tableData.length - 1]) <= currentLower) {
            const newLower = currentLower - 100 * tickSize;
            marketDataDOMRangeRef.current[contract_id][0] = newLower;
            pushNewRange(currentLower - tickSize, newLower, tickSize);
          }

          // Update upper bound and push new upper values if extended
          if (tableData[0] >= currentUpper) {
            const newUpper = currentUpper + 100 * tickSize;
            marketDataDOMRangeRef.current[contract_id][1] = newUpper;
            pushNewRange(newUpper, currentUpper + tickSize, tickSize);
          }
        }

        // Completely replace marketDataDOM for the given contract_id
        setMarketDataDOM({
          [contract_id]: {
            newRows,
            updateRows: tableData,
            centerId: centerPriceRef.current[contract_id],
          },
        });
      }
    },
    [setMarketDataDOM, products]
  );

  // Helper function to update contract reference count
  const updateDOMContractRefs = useCallback((contracts, updater) => {
    const newRefs = { ...contractDOMRefs.current };

    contracts.forEach((contract) => {
      const newCount = updater(newRefs[contract] || 0, contract); // Get the updated reference count

      if (newCount <= 0) {
        // Only delete if the contract is already in newRefs
        if (contract in newRefs) {
          delete newRefs[contract]; // Remove contract from contractRefs if count is 0
        }
      } else {
        newRefs[contract] = newCount; // Update the reference count
      }
    });

    contractDOMRefs.current = newRefs; // Manually update the ref
  }, []);

  // Register a single market data listener
  const registerMarketDataDOMListener = useCallback(
    (mapMarketData) => {
      if (!marketDataDOMListener.current) {
        marketDataDOMListener.current = (data) => {
          if (data.type === 'MarketDataDOMPrice') {
            handleMarketDataDOMUpdate(data.result, mapMarketData);
          }
        };

        webSocketInstance.addListener(
          'MarketDataDOMPrice',
          marketDataDOMListener.current
        );
      }
    },
    [handleMarketDataDOMUpdate, webSocketInstance]
  );

  // Batch Subscribe to market data
  const subscribeToMarketDataDOM = useCallback(
    (contracts, mapMarketData) => {
      const contractsToSubscribe = [];

      updateDOMContractRefs(contracts, (count, contract) => {
        if (count === 0) {
          contractsToSubscribe.push(contract);
        }
        return count + 1; // Increment contract reference count
      });
      if (contractsToSubscribe.length > 0 && webSocketInstance) {
        console.log(contractsToSubscribe);
        registerMarketDataDOMListener(mapMarketData);
        subscribeToMarketDataDOMHelper(
          webSocketInstance,
          contractsToSubscribe // Pass the entire batch
        );
      }
    },
    [webSocketInstance, registerMarketDataDOMListener, updateDOMContractRefs]
  );

  // Batch Unsubscribe from market data
  const unsubscribeFromMarketDataDOM = useCallback(
    (contracts) => {
      const contractsToUnsubscribe = [];

      // Collect contracts that need to be unsubscribed
      updateDOMContractRefs(contracts, (count, contract) => {
        if (count === 1) {
          contractsToUnsubscribe.push(contract);
        }
        return count - 1; // Decrement contract reference count
      });

      // Unsubscribe all collected contracts in a batch
      if (contractsToUnsubscribe.length > 0 && webSocketInstance) {
        unsubscribeFromMarketDataDOMHelper(
          webSocketInstance,
          contractsToUnsubscribe
        );
      }

      // If no contracts remain, remove the listener
      if (
        Object.keys(contractDOMRefs.current).length === 0 &&
        marketDataDOMListener.current
      ) {
        webSocketInstance.removeListener(
          'MarketDataDOMPrice',
          marketDataDOMListener.current
        );
        marketDataDOMListener.current = null;
      }
    },
    [webSocketInstance, updateDOMContractRefs]
  );

  ///////////////////////////////////////////////////////////////////////////////////
  //
  //
  const tshapeOptions = useCallback((optionList, shouldSort = false) => {
    // Combine options based on id and strike within the initializeTOptions function
    const combineOptions = (rows) => {
      return rows.reduce((acc, option) => {
        const { id } = option;

        if (!acc[id]) {
          // If the key doesn't exist, create a new combined object
          acc[id] = { id };
        }

        // Merge the current option data into the combined object
        acc[id] = { ...acc[id], ...option };

        return acc;
      }, {});
    };

    // Map through the optionList to initialize the options
    const initializedRows = optionList.map((option) => {
      // Determine the prefix based on optionType
      const prefix = option.optionType === 'C' ? 'call_' : 'put_';

      // Map each option with a prefixed key and relevant data
      return Object.keys(option).reduce(
        (acc, key) => {
          if (key !== 'id' && key !== 'symbol') {
            const updatedKey = `${prefix}${key}`;
            acc[updatedKey] = option[key];
          }
          return acc;
        },
        { id: option.strikePrice, strike: option.strikePrice }
      );
    });

    // Combine the rows based on id and strike
    const combinedOptions = combineOptions(initializedRows);

    // If `shouldSort` is true, sort the combined options by strike
    if (shouldSort) {
      const sortedKeys = Object.keys(combinedOptions).sort(
        (a, b) => combinedOptions[a].id - combinedOptions[b].id
      );

      // Rebuild the object with sorted keys
      const sortedCombinedOptions = {};
      sortedKeys.forEach((key) => {
        sortedCombinedOptions[key] = combinedOptions[key];
      });

      return sortedCombinedOptions;
    }

    // Return the combined options object as it is, without sorting
    return combinedOptions;
  }, []);

  useEffect(() => {
    if (Object.keys(isCalOptionData).length) {
      updateOptionsData(isCalOptionData);
    }
  }, [isCalOptionData]);

  const processOptionList = (optionList) => {
    // Initialize new objects for optionInputs and underlyings updates
    const newOptionInputs = {};
    const newUnderlyings = {};

    // Loop through optionList once to build updates
    Object.entries(optionList).forEach(([symbol, details]) => {
      // Populate newOptionInputs with updated values
      newOptionInputs[symbol] = {
        underlyingPrice: '', // Placeholder, update elsewhere
        optionType: details.optionType,
        strikePrice: details.strikePrice,
        optionPrice: '', // Placeholder, update elsewhere
        expTime: details.expTime,
        freeRisk: details.freeRisk,
        symbol: symbol,
      };

      // Collect underlyings for each contract
      if (details.underlying) {
        newUnderlyings[details.underlying] =
          newUnderlyings[details.underlying] || [];
        newUnderlyings[details.underlying].push(symbol);
      }
    });

    // Add newOptionInputs to the existing optionInputsRef
    optionInputsRef.current = {
      ...optionInputsRef.current, // Merge existing data
      ...newOptionInputs, // Add new data
    };

    // Add newUnderlyings to the existing underlyingsRef
    underlyingsRef.current = {
      ...underlyingsRef.current, // Merge existing data
      ...newUnderlyings, // Add new data
    };
  };

  // Subscribe to option Greeks based on the provided optionList
  const subOptionsGreekCal = (optionList) => {
    // Update optionInputsRef and underlyingsRef
    processOptionList(optionList);

    // Now handle subscriptions based on the updated optionInputsRef
    Object.keys(optionList).forEach((symbol) => {
      updateSubscriptionCount(symbol, 1); // Increment subscription count
    });
  };

  // Unsubscribe from option Greeks based on the provided optionList
  const unsubOptionsGreekCal = (symbols) => {
    Object.keys(symbols).forEach((symbol) => {
      updateSubscriptionCount(symbol, -1); // Decrement subscription count
    });
  };

  const updateSubscriptionCount = (symbol, increment) => {
    const currentCount = subscriptionCountRef.current[symbol] || 0;
    const newCount = currentCount + increment;

    if (newCount <= 0) {
      // Remove symbol from optionInputsRef and underlyingsRef if no subscriptions remain
      delete optionInputsRef.current[symbol];
      Object.keys(underlyingsRef.current).forEach((underlying) => {
        underlyingsRef.current[underlying] = underlyingsRef.current[
          underlying
        ].filter((contractId) => contractId !== symbol);
        if (underlyingsRef.current[underlying].length === 0) {
          delete underlyingsRef.current[underlying];
        }
      });
      delete subscriptionCountRef.current[symbol];
    } else {
      // Otherwise, update the count
      subscriptionCountRef.current[symbol] = newCount;
    }
  };

  useEffect(() => {
    return () => {
      const contracts = Object.keys(contractRefs.current);
      if (contracts.length > 0 && webSocketInstance) {
        unsubscribeFromMarketData(contracts);
      }
      if (marketDataListener.current && webSocketInstance) {
        webSocketInstance.removeListener(
          'MarketDataPrice',
          marketDataListener.current
        );
      }
      const contractsDOM = Object.keys(contractDOMRefs.current);
      if (contractsDOM.length > 0 && webSocketInstance) {
        unsubscribeFromMarketDataDOM(contractsDOM);
      }

      if (marketDataDOMListener.current && webSocketInstance) {
        webSocketInstance.removeListener(
          'MarketDataDOMPrice',
          marketDataDOMListener.current
        );
      }
    };
  }, [
    unsubscribeFromMarketData,
    webSocketInstance,
    unsubscribeFromMarketDataDOM,
  ]);

  return (
    <MarketDataContext.Provider
      value={{
        marketData,
        subscribeToMarketData,
        unsubscribeFromMarketData,
        initializeMarketData,
        subOptionsGreekCal,
        unsubOptionsGreekCal,
        tshapeOptions,
        marketDataDOM,
        subscribeToMarketDataDOM,
        unsubscribeFromMarketDataDOM,
      }}
    >
      {children}
    </MarketDataContext.Provider>
  );
};
