import React, { useEffect } from 'react';
import WindowComponentV2 from '../components/windows/WindowComponentV2';
import OrderEntryPanel from '../components/orderPanel/OrderEntryPanel';
import WindowComponent from '../components/windows/WindowComponent';
import { Box } from '@mui/material';
import { useWorkspaces } from '../contexts/WorkspacesContext';
import OrderNotification from '../components/orderActions/OrderNotification';

const workspaceId = 'tradex_watchList';
const workspaceLabel = 'Watchlist';
const tabsId = 'watchlist_tabs';
const tabContents = [{ componentId: 3 }];

const Watchlist = () => {
  const { setCurrentWorkspace } = useWorkspaces();

  useEffect(() => {
    setCurrentWorkspace(workspaceId);

    // If you need a cleanup, return a cleanup function here
    return () => {
      // Optional cleanup logic, if necessary
      setCurrentWorkspace(null); // Example cleanup
    };
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, height: '100%' }}>
      <Box sx={{ flex: 3, height: '100%' }}>
        <WindowComponentV2
          workspaceId={workspaceId}
          workspaceLabel={workspaceLabel}
          componentId={1}
          tabsId={tabsId}
          tabContents={tabContents}
        />
      </Box>
      <Box sx={{ flex: 1, height: '100%' }}>
        <WindowComponent title="Order Panel">
          <OrderEntryPanel />
        </WindowComponent>
      </Box>
      <OrderNotification />
    </Box>
  );
};

export default Watchlist;
