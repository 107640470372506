import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { Tabs, IconButton, useTheme, Box, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import TabPanel from './TabPanel';
import { useTabs } from '../../contexts/TabsContext';
import { useWorkspaces } from '../../contexts/WorkspacesContext';
import { componentMapper } from '../../utils/componentMapper';
import { tabsSaver } from '../../db/indexedDB/DBsaverMapper';
import useDataSaver from '../../db/indexedDB/useDataSaver';

function CustomTabs(props) {
  const { workspaceId, tabsId, tabContents, staticTabs = {} } = props;
  const { workspaces } = useWorkspaces();
  const workspacesRef = useRef(workspaces);
  const {
    tabs,
    setTabs,
    fetchAndSetTabs,
    initialTabs,
    initialSingleTab,
    switchSingleTab,
    reorderTabs,
    removeSingleTab,
    addSingleTab,
    updateTabLabel,
  } = useTabs();

  // Keep ref current
  useEffect(() => {
    workspacesRef.current = workspaces;
  }, [workspaces]);

  const [isLoading, setIsLoading] = useState(true);
  const [editingTabIndex, setEditingTabIndex] = useState(null); // Track which tab is being edited
  const [editingLabel, setEditingLabel] = useState(''); // Track the current editing label

  useEffect(() => {
    if (Object.keys(staticTabs).length > 0) {
      let myTabs = Object.entries(staticTabs).map(([key, value], index) => {
        return initialSingleTab(tabsId, key, value, index); // Pass max to initialSingleTab
      });
      setTabs((prev) => ({
        ...prev,
        [tabsId]: initialTabs(tabsId, myTabs, false),
      }));
      setIsLoading(false);
      return;
    }

    const DBTabsId = workspacesRef.current[workspaceId]?.tabsId;

    if (!DBTabsId || DBTabsId in tabs) {
      setIsLoading(false);
      return;
    }

    fetchAndSetTabs([DBTabsId]).then(() => {
      setTabs((prev) => {
        if (DBTabsId in prev) return prev;

        const newTab = initialSingleTab(tabsId, 'New Tab', tabContents);
        return {
          ...prev,
          [tabsId]: initialTabs(tabsId, [newTab], true),
        };
      });
      setIsLoading(false);
    });
  }, [workspaces]);

  const theme = useTheme(); // Access the theme
  // Memoize the canEdit value to prevent unnecessary recalculations
  const canEdit = useMemo(() => {
    return tabs[tabsId]?.editable ?? true; // Default to true if undefined
  }, [tabs, tabsId]);

  // Memoize the activeTabIndex to prevent unnecessary recalculations
  const activeTabIndex = useMemo(() => {
    return tabs[tabsId]?.activeTabIndex || 0; // Default to the first tab if undefined
  }, [tabs, tabsId]);

  const mousePos = useRef({ x: null, y: null });

  const handleMouseDown = (e, index) => {
    mousePos.current = { x: e.clientX, y: e.clientY };
    console.log(index);
  };

  const handleMouseUp = (e, index) => {
    const deltaX = Math.abs(mousePos.current.x - e.clientX);
    const deltaY = Math.abs(mousePos.current.y - e.clientY);
    console.log(deltaX);
    console.log(deltaY);
    if (deltaX < 5 && deltaY < 5) {
      switchSingleTab(tabsId, index);
    }
  };

  const onDragEnd = useCallback(
    (result) => {
      if (!result.destination) return;
      reorderTabs(tabsId, result.source.index, result.destination.index);
    },
    [reorderTabs, tabsId]
  );

  const handleTabChange = (event, newValue) => {
    switchSingleTab(tabsId, newValue);
  };

  const handleDoubleClick = (index) => {
    setEditingTabIndex(index); // Enter edit mode for this tab
    setEditingLabel(tabs[tabsId].tabsList[index].label); // Set the label for editing
  };

  const handleLabelChange = (e) => {
    setEditingLabel(e.target.value); // Update label on input change
  };

  const handleBlurOrSave = (index) => {
    // Save the new label and exit edit mode
    updateTabLabel(tabsId, index, editingLabel); // Update tab label in context
    setEditingTabIndex(null); // Exit edit mode
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Enter') {
      handleBlurOrSave(index); // Save when Enter is pressed
    }
  };

  const addNewTab = () => {
    // Check if tabContents is defined
    if (tabContents) {
      addSingleTab(tabsId, 'New Tab', tabContents);
    } else {
      // Define the default tabContents array
      const defaultTabContents = [
        {
          componentId: 2,
          props: {},
        },
      ];
      // Use the default tabContents array
      addSingleTab(tabsId, 'New Tab', defaultTabContents);
    }
  };

  const renderClone = useCallback(
    (provided, snapshot, rubric) => {
      return (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          onMouseDown={(e) => handleMouseDown(e, rubric.source.index)}
          onMouseUp={(e) => handleMouseUp(e, rubric.source.index)}
          style={{
            padding: 2,
            userSelect: 'none',
            ...provided.draggableProps.style,
            backgroundColor: snapshot.isDragging
              ? theme.palette.action.selected // Example: Change to a theme-based color
              : activeTabIndex === rubric.source.index
                ? theme.palette.background.default // Use default background
                : theme.palette.background.paper, // Use paper color for inactive tabs
            color:
              activeTabIndex === rubric.source.index
                ? theme.palette.text.primary // Text color for active tab
                : theme.palette.text.disabled, // Text color for inactive tabs
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center', // Align items vertically in the center
              justifyContent: 'space-between', // Space between the label and the close button
              width: '100%', // Ensure the box takes full width of the tab
              padding: '0 8px', // Add padding for spacing
            }}
          >
            {editingTabIndex === rubric.source.index && canEdit ? (
              <TextField
                value={editingLabel}
                onChange={handleLabelChange}
                onBlur={() => handleBlurOrSave(rubric.source.index)}
                onKeyDown={(e) => handleKeyDown(e, rubric.source.index)}
                autoFocus
                fullWidth
                size="small"
                variant="outlined"
                inputProps={{ style: { padding: '6px' } }}
              />
            ) : (
              <Box
                sx={{
                  flexGrow: 1,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  cursor: canEdit ? 'pointer' : 'default',
                }}
                onDoubleClick={() =>
                  canEdit && handleDoubleClick(rubric.source.index)
                }
              >
                {tabs[tabsId].tabsList[rubric.source.index].label}
              </Box>
            )}

            {canEdit && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  removeSingleTab(tabsId, rubric.source.index, canEdit);
                }}
                size="small"
                sx={{ ml: 1 }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            )}
          </Box>
        </div>
      );
    },
    [
      activeTabIndex,
      editingTabIndex,
      canEdit,
      editingLabel,
      handleMouseDown,
      handleMouseUp,
      handleLabelChange,
      handleBlurOrSave,
      handleKeyDown,
      handleDoubleClick,
      removeSingleTab,
      tabs,
      tabsId,
      theme,
    ]
  );

  // Memoize the object, but return an empty object if autoSave is false
  const saveIdsObj = useMemo(
    () => ({ [tabsSaver]: [tabsId] }),
    [tabsId, tabsSaver]
  );

  // Only call useDataSaver if autoSave is true
  useDataSaver(saveIdsObj);

  if (isLoading) {
    return <div>Loading...</div>; // Or any other loading indicator
  }

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId="droppable-tabs"
          direction="horizontal"
          renderClone={renderClone}
        >
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                display: 'flex',
                width: '100%', // Ensuring full width to contain the Tabs
              }}
            >
              <Tabs
                value={activeTabIndex} // Corrected to use activeTab
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
              >
                {tabs[tabsId]?.tabsList?.map((tab, index) => (
                  <Draggable
                    key={tab.tabId}
                    draggableId={tab.tabId}
                    index={index}
                  >
                    {renderClone}
                  </Draggable>
                ))}
                {canEdit && (
                  <IconButton onClick={addNewTab}>
                    <AddIcon />
                  </IconButton>
                )}
              </Tabs>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Box sx={{ flexGrow: 1, height: '100%' }}>
        {/* Flex-grow to fill remaining space */}
        {/* Display active tab content */}
        {tabs[tabsId]?.tabsList?.map((tab, index) => (
          <TabPanel key={tab.tabId} value={activeTabIndex} index={index}>
            {tab.contents.map((content, idx) => {
              const Component = componentMapper[content.componentId]; // Get the component from the mapper
              const props = content.props ? content.props : {}; // Conditionally add props if content.props exists
              return Component ? <Component key={idx} {...props} /> : null; // Pass the props to the component
            })}
          </TabPanel>
        ))}
      </Box>
    </Box>
  );
}

export default React.memo(CustomTabs);
