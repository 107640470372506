// auto save data into index db managament

import { useEffect, useRef, useCallback } from 'react';
import { useWorkspaces } from '../../contexts/WorkspacesContext';
import { useTabs } from '../../contexts/TabsContext';
import { useTable } from '../../contexts/TableContext';
import { useOptionChain } from '../../contexts/OptionChainContext';
import {
  tabsSaver,
  workSpaceSaver,
  tableSaver,
  optionChainSaver,
} from './DBsaverMapper';

const useDataSaver = (dataIdsObj) => {
  const { workspaces, updateWorkSpace } = useWorkspaces();
  const workspacesRef = useRef(workspaces);
  const { tabs, updateTabs } = useTabs();
  const tabsRef = useRef(tabs);
  const { tables, updateTable } = useTable();
  const tablesRef = useRef(tables);
  const { optionChains, updateOptionChains } = useOptionChain();
  const optionChainsRef = useRef(optionChains);
  const dataIdsObjRef = useRef(dataIdsObj);

  // Keep ref current
  useEffect(() => {
    workspacesRef.current = workspaces;
  }, [workspaces]);

  // Keep ref current
  useEffect(() => {
    tabsRef.current = tabs;
  }, [tabs]);

  useEffect(() => {
    tablesRef.current = tables;
  }, [tables]);

  const saveData = useCallback((dataIdsObj) => {
    if (!dataIdsObj) {
      return;
    }
    for (const [key, value] of Object.entries(dataIdsObj)) {
      switch (key) {
        case tabsSaver:
          updateTabs(value, tabsRef.current);
          break;
        case workSpaceSaver:
          updateWorkSpace(value, workspacesRef.current);
          break;
        case tableSaver:
          updateTable(value, tablesRef.current);
          break;
        case optionChainSaver:
          updateOptionChains(value, optionChainsRef.current);
          break;
        default:
          console.warn(`No matching function for key: ${key}`);
      }
    }
  }, []);

  useEffect(() => {
    dataIdsObjRef.current = dataIdsObj; // Keep the latest value in ref
  }, [dataIdsObj]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      saveData(dataIdsObjRef.current);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      saveData(dataIdsObjRef.current);
    };
  }, [saveData, tables]);
  return null;
};

export default useDataSaver;
