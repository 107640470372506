const ErrorMapper = {
  //general error
  1000: '',
  //DB error
  1001: 'Failed to open database.',
  // broadcastChannel error
  2001: 'Channel Name not found',
  // websocket
  3001: 'WebSocket connection timeout',
};

export const getErrorMessage = (
  errorCode,
  eMsg = '',
  defaultMsg = 'An unknown error occurred'
) => {
  // Collect potential parts of the message
  const parts = [];

  // If there's a mapped error message, add it
  if (ErrorMapper[errorCode]) {
    parts.push(ErrorMapper[errorCode]);
  }

  // If there's an additional error message passed, add it
  if (eMsg) {
    parts.push(eMsg);
  }

  // Join parts with a space or return the default message if no parts are added
  return parts.length > 0 ? parts.join(' ') : defaultMsg;
};
