import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';

const ReusableDialog = ({
  title, // String: title of the dialog
  children, // JSX: the content inside the dialog
  onConfirm, // Function: action when the confirm button is clicked (optional)
  confirmText = 'Confirm', // String: custom text for confirm button (optional)
  cancelText = 'Cancel', // String: custom text for cancel button (optional)
  maxWidth = 'md', // String: dialog size (optional, default is 'md')
  fullWidth = true, // Boolean: whether the dialog should take full width (optional)
  titleBgColor = '',
}) => {
  const [open, setOpen] = useState(true); // Track whether the dialog is open or closed

  // Open the dialog
  const handleOpen = () => {
    setOpen(true);
  };

  // Close the dialog
  const handleClose = () => {
    setOpen(false);
  };

  // Confirm action handler
  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm(); // Execute the passed confirmation function (if any)
    }
    setOpen(false); // Close the dialog after confirming
  };

  return (
    <>
      {/* MUI Dialog for General Use */}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={maxWidth}
        fullWidth={fullWidth}
      >
        <DialogTitle
          sx={{
            backgroundColor: titleBgColor || 'primary.main', // Use custom background color or fallback to the default theme primary color
          }}
        >
          {title}
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button variant="contained" color="error" onClick={handleClose}>
            {cancelText}
          </Button>
          <Button variant="contained" color="success" onClick={handleConfirm}>
            {confirmText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReusableDialog;
