import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from 'react';
import VTable from '../tables/VTable';
import { baseWatchListConfig } from './watchListConfig';
import { useTable } from '../../contexts/TableContext';
import { useMetadata } from '../../contexts/MetadataContext';
import { mapMarketDataUpdate } from '../../services/websocket/marketdata/MarketDataMapper';
import { useMarketData } from '../../contexts/MktDataContext';
import { calculateDifferences } from '../../utils/filterHelper';
import MarketDepth from '../marketDepth/MarketDepth';
import { useWindow } from '../../contexts/WindowContext ';
import { generateUniqueId } from '../../utils/general';

const WatchList = ({ tableId }) => {
  const { setActiveTableId, tables, setTables } = useTable();
  const { formatOptionData } = useMetadata();
  const {
    marketData,
    subscribeToMarketData,
    unsubscribeFromMarketData,
    initializeMarketData,
    subOptionsGreekCal,
    unsubOptionsGreekCal,
    subscribeToMarketDataDOM,
  } = useMarketData();

  const totalContracts = useRef([]);
  const totalOptions = useRef({});
  const totalUnderlyings = useRef([]);
  const [initializedData, setInitializedData] = useState([]);
  const hasInitialized = useRef(false);
  const [removeRows, setRemoveRows] = useState([]);
  const { openWindow } = useWindow();

  useEffect(() => {
    setActiveTableId(tableId);
  }, [tableId, setActiveTableId]);

  // UseCallback for deleteRowFromTable to prevent it from being re-created on every render
  const deleteRowFromTable = useCallback(
    (params) => {
      const rowToDelete = params.node?.data?.symbol; // Assuming 'symbol' is the row's unique identifier

      setTables((prevTables) => {
        const updatedRows = prevTables[tableId].rows.filter(
          (row) => row.symbol !== rowToDelete
        );

        return {
          ...prevTables,
          [tableId]: {
            ...prevTables[tableId],
            rows: updatedRows,
          },
        };
      });
    },
    [tableId, setTables]
  );

  const showMktDepth = useCallback(
    (params) => {
      const contract = params.node?.data?.symbol;
      const windowId = 'marketDepthWindow' + generateUniqueId();
      // Open the window and get the window ID
      const props = { contract: contract };

      // Set the window name immediately for the new window

      const ct = openWindow(windowId, MarketDepth, props, {
        title: 'Market Depth',
      });
    },

    [openWindow]
  );

  // Memoize the updated watchListConfig with the deleteRowFromTable action
  const watchListConfig = useMemo(() => {
    return {
      ...baseWatchListConfig,
      contextMenuConfig: {
        delete: deleteRowFromTable,
        mktdepth: showMktDepth,
      }, // Add deleteRowFromTable into the config
    };
  }, [deleteRowFromTable]);

  // Memoized optionList and contractList in one pass
  const { contractList, optionList, underlyingList } = useMemo(() => {
    const rows = tables[tableId]?.rows || [];
    const contractSymbols = new Set();
    const underlyingSymboles = new Set();
    const acc = {}; // For optionList

    // Iterate over rows once
    rows.forEach((contractData) => {
      contractSymbols.add(contractData.symbol); // Add the symbol to the contract list

      if (contractData?.product_type === 2) {
        // Add option data and the underlying symbol to the contract list
        acc[contractData.symbol] = formatOptionData(contractData);
        underlyingSymboles.add(acc[contractData.symbol].underlying);
      }
    });

    return {
      contractList: Array.from(contractSymbols), // Convert Set to Array
      optionList: acc,
      underlyingList: Array.from(underlyingSymboles),
    };
  }, [tables, tableId]);

  // Handle changes in contractList
  useEffect(() => {
    const { newItems: newContracts, removedItems: removedContracts } =
      calculateDifferences(contractList, totalContracts.current);

    const {
      newItems: newUnderlyingContracts,
      removedItems: removedUnderlyingContracts,
    } = calculateDifferences(underlyingList, totalUnderlyings.current);

    // Process new contracts
    if (newContracts.length > 0) {
      // Check if filteredContracts has items
      const initializedMarketData = initializeMarketData(newContracts);
      setInitializedData(Object.values(initializedMarketData));

      hasInitialized.current = true;
      totalContracts.current = [...totalContracts.current, ...newContracts];
      subscribeToMarketData(newContracts, mapMarketDataUpdate);
    }

    if (newUnderlyingContracts.length > 0) {
      totalUnderlyings.current = [
        ...totalUnderlyings.current,
        ...newUnderlyingContracts,
      ];
      subscribeToMarketData(newUnderlyingContracts, mapMarketDataUpdate);
    }

    // Process removed contracts
    if (removedContracts.length > 0) {
      unsubscribeFromMarketData(removedContracts);

      const removeRows = removedContracts.map((contract) => ({
        symbol: contract,
      }));
      setRemoveRows(removeRows);

      totalContracts.current = totalContracts.current.filter(
        (contract) => !removedContracts.includes(contract)
      );
    }

    if (removedUnderlyingContracts.length > 0) {
      unsubscribeFromMarketData(removedUnderlyingContracts);
      totalUnderlyings.current = totalUnderlyings.current.filter(
        (contract) => !removedUnderlyingContracts.includes(contract)
      );
    }
  }, [
    contractList,
    underlyingList,
    initializeMarketData,
    subscribeToMarketData,
    unsubscribeFromMarketData,
  ]);

  // Handle changes in optionList
  useEffect(() => {
    const { newItems: newOptionsKeys, removedItems: removedOptions } =
      calculateDifferences(
        Object.keys(optionList),
        Object.keys(totalOptions.current)
      );

    // Prepare newOptions object by collecting the data for the new keys
    const newOptions = newOptionsKeys.reduce((acc, key) => {
      acc[key] = optionList[key];
      return acc;
    }, {});

    // Process new options
    if (Object.keys(newOptions).length > 0) {
      // Merge the new options into totalOptions.current
      totalOptions.current = {
        ...totalOptions.current,
        ...newOptions, // Add new options
      };

      // Pass the new options data to subOptionsGreekCal
      subOptionsGreekCal(newOptions);
    }

    // Process removed options
    if (removedOptions.length > 0) {
      // Remove each key from totalOptions.current
      removedOptions.forEach((key) => {
        delete totalOptions.current[key];
      });

      // Pass the removed options to unsubOptionsGreekCal
      unsubOptionsGreekCal(removedOptions);
    }
  }, [optionList]);

  // Handle component unmount only
  useEffect(() => {
    return () => {
      // This will only run when the component unmounts
      if (totalContracts.current.length > 0) {
        unsubscribeFromMarketData(totalContracts.current);
        // we need to reset totalContract as well.
        totalContracts.current = [];
        totalOptions.current = {};
        totalUnderlyings.current = [];
      }
    };
  }, [unsubscribeFromMarketData]); // Empty dependency array ensures it runs only on unmount

  const updateRows = useMemo(() => {
    if (!hasInitialized.current) return [];

    const contractSymbols = new Set(contractList); // Assuming contractList holds the symbols (keys) of the contracts
    return Object.values(marketData).filter((data) =>
      contractSymbols.has(data.symbol)
    );
  }, [marketData, contractList, hasInitialized.current]);

  return (
    <>
      {/* VTable component rendering */}
      <VTable
        tableId={tableId}
        insertRows={initializedData}
        updateRows={updateRows}
        config={watchListConfig}
        deleteRows={removeRows}
      />
    </>
  );
};

export default React.memo(WatchList);
