export const getContextMenuItems = (params) => {
  const { context } = params;
  const { contextMenuConfig } = context; // Destructure contextMenuConfig from context

  const items = [];

  // Iterate over the contextMenuConfig object entries and conditionally add custom items
  Object.entries(contextMenuConfig)?.forEach(([menuItem, menuValue]) => {
    switch (menuItem) {
      case 'delete':
        items.push({
          name: 'Delete',
          action: () => {
            menuValue(params);
          },
        });
        break;

      case 'mktdepth':
        items.push({
          name: 'Market Depth',
          action: (params) => {
            const rowtoDOM = params.node?.data;
            if (!rowtoDOM) {
              alert('Please select a contract first!');
            } else {
              // Assuming the value for amend is the dialog content or configuration
              // You will need to trigger the dialog in a parent component or with a state
              menuValue(params);
            }
          },
        });
        break;
      case 'amend':
        items.push({
          name: 'Amend',
          action: () => {
            const rowtoAmend = params.node?.data;
            if (!rowtoAmend) {
              alert('Please select a order first!');
            } else {
              // Assuming the value for amend is the dialog content or configuration
              // You will need to trigger the dialog in a parent component or with a state
              menuValue(params);
            }
          },
        });
        break;

      case 'edit':
        items.push({
          name: 'Edit',
          action: () => {
            alert(`Editing row: ${params.value} with value: ${menuValue}`);
          },
        });
        break;

      default:
        break;
    }
  });

  // Add a separator only if there are custom items
  if (Object.keys(contextMenuConfig).length > 0) {
    items.push('separator'); // Add separator before the default actions
  }

  // Add default actions that will always be available
  items.push('copy', 'paste', 'export'); // AG Grid built-in actions

  return items;
};
