import React from 'react';
import Dropdown from './dropdownComponent'; // Correct way to import default exports
import { useOptionChain } from '../../contexts/OptionChainContext';

const OptionsDropdown = ({ optionProductArray }) => {
  const { setOptionChains, activeOptionChainId, optionChains } =
    useOptionChain();
  // Transform the products object into a sorted array suitable for a dropdown

  const handleChange = (event) => {
    const newSelectedProduct = event.target.value;

    setOptionChains((prev) => ({
      ...prev,
      [activeOptionChainId]: {
        ...prev[activeOptionChainId],
        selectedProduct: newSelectedProduct,
        selectedContract: '',
      },
    }));
  };

  return (
    <Dropdown
      label="Select Product"
      items={optionProductArray || []}
      valueKey="key" // Assuming 'id' is the unique identifier for the products
      labelKey="key" // Assuming 'product_name' is what you want to display
      value={optionChains[activeOptionChainId]?.selectedProduct || ''}
      onChange={handleChange}
    />
  );
};

export default OptionsDropdown;
