// hooks/useConnectionMetrics.js
import { useState, useEffect } from 'react';
import { useWebSocket } from '../contexts/WebSocketContext'; // Adjust the import path

export const useConnectionMetrics = () => {
  const [metrics, setMetrics] = useState({});
  const { webSocketInstance } = useWebSocket();

  useEffect(() => {
    const updateMetrics = (newMetrics) => {
      setMetrics(newMetrics);
    };

    if (webSocketInstance) {
      webSocketInstance.setMetricsCallback(updateMetrics);
    }

    // Cleanup function to remove the metrics callback
    return () => {
      if (webSocketInstance) {
        webSocketInstance.clearMetricsCallback();
      }
    };
  }, [webSocketInstance]);

  return metrics;
};
