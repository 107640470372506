import axios from 'axios';
import globalConfig from '../config/config';

// Function to log in the user and return the token
async function loginUser(email, password) {
  // API endpoint for user login
  const LOGIN_URL = `${globalConfig.baseURL}/user/login`; // Ensure your BASE_URL ends without a slash
  try {
    const response = await axios.post(LOGIN_URL, {
      user_id: email,
      password: password,
      // Optional fields, replace 'string' with actual values if needed
      login_version: 'string',
      ip_address: 'string',
      mac_address: 'string',
    });

    // Check if the response code is 3000 and throw an error with additional details
    if (response.data.response_code === 3000) {
      const { error_code, error_message, action_message } = response.data;
      // Construct a custom error object and throw it
      const error = new Error(error_message);
      error.error_code = error_code;
      error.error_message = error_message;
      error.action_message = action_message; // Attach the action message to the error object
      throw error; // Throw the error to be handled in the catch block
    }

    // Assuming the response contains a token within response.data.result.token
    const token = response.data.result.token;
    // Store the token in localStorage or handle it as needed
    localStorage.setItem('id_token', token);

    // Return the token for further processing in the component
    return response.data.result;
  } catch (error) {
    // Rethrow the error to be caught by the calling component
    console.log(error);
    throw error;
  }
}

// Function to sign out the user
function signOut() {
  // Remove the token from local storage
  localStorage.removeItem('id_token');

  // Additional logout logic if needed
}

export { loginUser, signOut };
