// Assuming optionsCalculatorWorker.js is adjusted to not terminate on its end

let worker;

function initializeWorker() {
  if (!worker) {
    // Adjust the URL according to your project structure
    worker = new Worker('/optionsCalculationWorker.js');
  }
}

// Async function to calculate options
export async function calculateOptions(data, taskType) {
  initializeWorker();

  // Creating a promise that resolves when the web worker sends back a message
  const resultPromise = new Promise((resolve, reject) => {
    worker.onmessage = (event) => {
      if (event.data.taskType === 'GREEKS_RESULT') {
        resolve(event.data.results);
      }
    };

    worker.onerror = (event) => {
      reject(new Error(event.message));
    };
  });

  // Post message to worker
  worker.postMessage({ data, taskType });

  // Await the promise to get the result
  try {
    const result = await resultPromise;
    return result;
  } catch (error) {
    console.error(error);
    throw error; // Rethrow to allow caller to handle it
  }
}

export function calculateYearsUntilExpiration(expirationDateStr) {
  // Parse the date string "YYYYMMDD"
  const year = parseInt(expirationDateStr.substring(0, 4), 10);
  const month = parseInt(expirationDateStr.substring(4, 6), 10) - 1; // JS months are 0-indexed
  const day = parseInt(expirationDateStr.substring(6, 8), 10);

  // Create a date object for the expiration date
  const expirationDate = new Date(year, month, day);

  // Get the current date
  const currentDate = new Date();

  // Calculate the difference in milliseconds
  const diffInMs = expirationDate - currentDate;

  // Convert milliseconds to years (1000 milliseconds/sec, 60 sec/min, 60 min/hour, 24 hours/day, ~365.25 days/year)
  const diffInYears = diffInMs / (1000 * 60 * 60 * 24 * 365.25);

  return diffInYears;
}
