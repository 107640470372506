import React, { useEffect, useMemo } from 'react';
import { Box, Paper, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import WindowControls from './WindowControls';
import { Resizable } from 're-resizable';
import { useWorkspaces } from '../../contexts/WorkspacesContext';
import { componentMapper } from '../../utils/componentMapper';
import CustomTabs from '../tabs/CustomTabs';
import { workSpaceSaver } from '../../db/indexedDB/DBsaverMapper';
import useDataSaver from '../../db/indexedDB/useDataSaver';

const StyledPaper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  overflow: 'hidden',
});

const HeaderBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  borderBottom: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const ContentBox = styled(Box)({
  flexGrow: 1,
  overflow: 'auto',
  height: '100%',
});

const WindowComponentV2 = (props) => {
  const { workspaceId, workspaceLabel, componentId, tabsId, tabContents } =
    props;
  const theme = useTheme();
  const {
    workspaces,
    setWorkspaces,
    fetchAndSetWorkspaces,
    initialWorkspaceState,
  } = useWorkspaces();

  useEffect(() => {
    if (!(workspaceId in workspaces)) {
      fetchAndSetWorkspaces([workspaceId]).then(() => {
        // Using a callback to check the updated state
        setWorkspaces((prev) => {
          if (!(workspaceId in prev)) {
            const windowProps = {
              title: workspaceLabel, // Assuming you want the label if not found
              componentId: componentId,
            };
            return {
              ...prev,
              [workspaceId]: initialWorkspaceState(
                workspaceId,
                workspaceLabel,
                tabsId,
                windowProps
              ),
            };
          }
          return prev;
        });
      });
    }
  }, [workspaceId, workspaceLabel]);

  const thisComponentId = useMemo(() => {
    return workspaces[workspaceId]?.windowProps?.componentId;
  }, [workspaces, workspaceId]);

  const ComponentToRender = useMemo(
    () => componentMapper[thisComponentId],
    [thisComponentId]
  );

  const saveIdsObj = {
    [workSpaceSaver]: [workspaceId],
  };

  useDataSaver(saveIdsObj);

  if (!workspaces[workspaceId]) {
    return <div>Loading...</div>;
  }

  return (
    <Resizable
      id="my-resizable"
      defaultSize={{ width: '100%', height: '100%' }}
      style={{ display: 'flex', flexDirection: 'column' }}
      enable={{
        top: true,
        right: true,
        bottom: true,
        left: true,
        topRight: true,
        bottomRight: true,
        bottomLeft: true,
        topLeft: true,
      }}
    >
      <StyledPaper style={{ backgroundColor: theme.palette.background.paper }}>
        <HeaderBox theme={theme}>
          <Typography variant="h6">
            {workspaces[workspaceId].windowProps.title}
          </Typography>
          <WindowControls>
            {ComponentToRender ? <ComponentToRender /> : null}
          </WindowControls>
        </HeaderBox>
        <ContentBox>
          <CustomTabs
            workspaceId={workspaceId}
            tabsId={tabsId}
            tabContents={tabContents}
          />
        </ContentBox>
      </StyledPaper>
    </Resizable>
  );
};
export default React.memo(WindowComponentV2);
