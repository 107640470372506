import React, {
  useEffect,
  useMemo,
  useRef,
  useCallback,
  useState,
} from 'react';
import VTable from '../tables/VTable';
import WindowComponent from '../windows/WindowComponent';
import { useTable } from '../../contexts/TableContext';
import { useOrders } from '../../contexts/OrderContext';
import { mapOrdersUpdate } from '../../services/websocket/orders/OrderMapper';
import { processingColumns } from '../../pages/orders/OrderCols';
import { useMetadata } from '../../contexts/MetadataContext';
import OrderDialog from '../orderActions/OrderDialog';
import OrderNotification from '../orderActions/OrderNotification';

const workingOrders = [0, 1, 2, 3, 4, 5, 14, 16, 22];
const tableId = 'workingOrdersTable';
const baseOrdersConfig = {
  autoSizeMode: 'sizeColumnsToContentStrategy',
  cols: processingColumns,
  autoSave: true,
  paginationPageSize: 200,
};

const WorkingOrders = () => {
  const { setActiveTableId } = useTable();
  const { orders, toDeleteOrders, setTodeleteOrders } = useOrders();
  const isInitializedRef = useRef(false);
  const [orderGroups, setOrderGroups] = useState([]); // Replacing useRef with useState
  const { enumList } = useMetadata();
  const [isOpen, setIsOpen] = useState(false);
  const toOrderRef = useRef(null);
  const titleRef = useRef('');
  const [actionType, setActionType] = useState('');

  const handleTableFocus = useCallback(() => {
    setActiveTableId(tableId);
  }, [setActiveTableId]);

  useEffect(() => {
    console.log('working Orders: setActiveTableId called');
    setActiveTableId(tableId);
  }, [setActiveTableId]);

  // This is the amendOrder function, which opens the dialog and sets the current order to be amended
  const amendOrder = useCallback(
    (params) => {
      // Update the amendOrderRef with the selected order details
      toOrderRef.current = orders.accumulatedOrders[params.node?.id];
      // Close the dialog first if it's already open
      setIsOpen(false);
      titleRef.current = 'Amend Order';
      setActionType('Amend');

      // Re-open the dialog after a slight delay to ensure content updates
      setTimeout(() => {
        setIsOpen(true);
      }, 0); // Small delay to allow the dialog to close and reopen
    },
    [setIsOpen, orders.accumulatedOrders] // Include theme and setIsOpen as dependencies
  );

  useEffect(() => {
    if (toDeleteOrders.length > 0) {
      toOrderRef.current = toDeleteOrders[0];
      titleRef.current = 'Cancel Order';
      setActionType('Delete');
      setIsOpen(false);
      // Re-open the dialog after a slight delay to ensure content updates
      setTimeout(() => {
        setIsOpen(true);
      }, 0); // Small delay to allow the dialog to close and reopen
      setTodeleteOrders([]);
    }
  }, [toDeleteOrders]);

  // Memoize the updated watchListConfig with the deleteRowFromTable action
  const ordersConfig = useMemo(() => {
    return {
      ...baseOrdersConfig,
      contextMenuConfig: { amend: amendOrder }, // Add deleteRowFromTable into the config
    };
  }, [amendOrder]);

  // Manage initial rows (iWRows) and inserted rows (insertedWRows)
  const iRows = useMemo(() => {
    let updatedOrders = [];

    if (!isInitializedRef.current && orders.accumulatedOrders && enumList) {
      isInitializedRef.current = true;
      updatedOrders = Object.values(orders.accumulatedOrders);
    } else if (isInitializedRef.current && orders.newOrder) {
      updatedOrders = [orders.newOrder];
    } else {
      return [];
    }

    const workingRows = updatedOrders
      .filter((order) => workingOrders.includes(order.order_status))
      .map((order) => {
        setOrderGroups((prevGroups) => {
          // Ensure each order gets added based on the previous state
          return [...prevGroups, order.client_order_id];
        });
        return mapOrdersUpdate(order, enumList);
      });

    return workingRows; // Return both initial and inserted rows (same for now)
  }, [orders.accumulatedOrders, orders.newOrder, enumList]);

  // Manage updated, inserted, and removed rows (uRows, iuRows, rRows)
  const [uRows, iuRows, rRows] = useMemo(() => {
    if (!orders.updateOrder || !enumList) return [[], [], []];

    const comingOrder = mapOrdersUpdate(orders.updateOrder, enumList);
    const orderId = comingOrder.orderId;

    let updatedRows = [],
      insertedRows = [],
      removedRows = [];

    // Check if the order needs to be updated, inserted, or removed
    if (workingOrders.includes(orders.updateOrder.order_status)) {
      if (orderGroups.includes(orderId)) {
        updatedRows.push(comingOrder);
      } else {
        insertedRows.push(comingOrder);
        setOrderGroups((prevGroups) => {
          // Ensure each order gets added based on the previous state
          return [...prevGroups, orderId];
        });
      }
    } else {
      if (orderGroups.includes(orderId)) {
        removedRows.push(comingOrder);
        setOrderGroups((prevGroups) =>
          prevGroups.filter((id) => id !== orderId)
        ); // Trigger re-render
      } else {
        console.log(orderGroups);
        console.log('hi', orderId);
      }
      // console.log('order_id', orderId)
    }

    return [updatedRows, insertedRows, removedRows];
  }, [orders.updateOrder, enumList]);

  // Combine initial and inserted rows
  const combinedWRows = useMemo(() => {
    const uniqueOrdersMap = new Map();

    // Combine both iRows and iuRows
    const combinedRows = [...iRows, ...iuRows];

    combinedRows.forEach((order) => {
      // Use orderId as the key to ensure uniqueness
      uniqueOrdersMap.set(order.orderId, order);
    });

    // Return an array of unique orders
    return Array.from(uniqueOrdersMap.values());
  }, [iRows, iuRows]);

  return (
    <div onClick={handleTableFocus} style={{ height: '100%', width: '100%' }}>
      <WindowComponent title="Working Orders">
        <VTable
          tableId={tableId}
          insertRows={combinedWRows}
          updateRows={uRows}
          deleteRows={rRows}
          config={ordersConfig}
        />
      </WindowComponent>

      <OrderDialog
        isOpen={isOpen}
        amendOrderRef={toOrderRef}
        actionType={actionType}
        title={titleRef.current}
      />
      <OrderNotification />
    </div>
  );
};

export default WorkingOrders;
