import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import createMyTheme from './theme';
import { useTranslation } from '../hooks/useTranslation';

function ThemedApp({ children }) {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const theme = createMyTheme('dark', currentLanguage);

  return (
    <ThemeProvider theme={theme}>
      {/* Ensures that the background color of the theme is applied to the body */}
      <CssBaseline />
      {children} {/* Pass children to render the rest of the app */}
    </ThemeProvider>
  );
}

export default ThemedApp;
